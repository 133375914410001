import styled from "styled-components";
import { LectureListSearch, LectureViewTable } from "../Lecture/styles";

export const HomeWrapper = styled.div`
  padding: 5px 300px;
  display: flex;
  gap: 14px;
  min-width: 360px;
  padding-bottom: 0px;
  @media (max-width: 1700px) {
    padding: 5px 220px;
  }
  @media (max-width: 1550px) {
    padding: 5px 130px;
  }
  @media (max-width: 1350px) {
    padding: 5px 80px;
  }
  @media (max-width: 1250px) {
    padding: 5px 50px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 700px) {
    padding: 5px 12px;
  }
`
export const Icon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TodayLecture = styled.div`
  width: 624px;
  border: 3px solid #F1EBEB;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`
export const Title = styled.p`
  display: flex;
  gap: 12px;
  align-items: center;
  color: #222222;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  span {
    color: #666666;
    font-weight: 500;
  }
`
export const Table = styled.table`
  width: 100%;
  background-color: #fff;
  text-align: center;
  th {
    padding: 10px 12px;
    border: 1px solid #F6F1F1;
    background-color: #FAE7E7;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #666;
  }
  tr {
    cursor: pointer;
    &:hover {
      background-color: #f78b8530 !important;
    }
  }
  td {
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #F6F1F1;
    border-top: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #111;
    &:first-child {
      width: 40px;
    }
    &:last-child {
      border-right: 1px solid #F6F1F1;
      span {
        color: #DC3838;
      }
    }
    input {
      height: 100%;
      padding: 0 5px;
    }
  }
  .tdName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .learnNote {
      padding: 5px 8px;
      width: 84px;
      gap: 2px;
    }
  }
  @media (max-width: 700px) {
    th {
      &:first-child {
        display: none;
      }
    }
    td {
      &:first-child {
        display: none;
      }
    }
    .person {
      display: none;
    }
  }
  @media (max-width: 400px) {
    .tdName {
      ${Icon} {
        display: none;
      }
    }
  }
`

export const SelectLecture = styled.div`
  background-color: #fff;
  width: 682px;
  min-height: 300px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`
export const NotSelectLecture = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -1px;
  }
`
export const IsSelectLecture = styled.div`
  width: 100%;
  position: relative;
` 
export const LectureHeader = styled.div`
  width: 100%;
  height: 52px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F78B85;
  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -1px;
      color: #fff;
    }
    span {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -1px;
      color: #FFF0EF;
    }
  }
`
export const AttendanceWrapper = styled.div`
  display: flex;
`
export const AttendanceDisplay = styled.div`
  width: 50%;
  height: 100%;
  min-height: 300px;
`
export const AttendanceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  padding: 0px 20px;
  background-color: #FAE7E7;
  letter-spacing: -1px;
  span {
    &:first-child {
      color: #666;
    }
    &:last-child {
      color: #222;
    }
  }
`
export const AttendanceList = styled.div`
  overflow-y: auto;
  max-height: 700px;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    align-self: stretch;
    height: 50px;
    border-bottom: 1px solid #F6F1F1;
    span {
      font-size: 16px;
      color: #111;
      letter-spacing: -1px;
    }
    &:hover {
      background-color: #f78b8530;
    }
  }
  button {
    padding: 6px;
    background-color: #222;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 15px;
    font-family: Pretendard-Regular;
    font-weight: 500;
  }
`
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid #525252;
  padding: 6px 10px;
  border-radius: 4px;
  width: 100px;
  background-color: #fff;
  color: #222;
  z-index: 1;
  p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -1px;
  }
`
export const IsAdd = styled.div`
  position: absolute;
  width: 100%;
  background-color: #fff;
  .AddHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #FFF9F9;
    ${LectureListSearch} {
      width: 80%;
    }
    .btns {
      width: 20%;
      display: flex;
      align-items: center;
      button {
        width: 50%;
        padding: 0px 10px;
        height: 100%;
      }
      .add {
        background-color: #F06B64;
        color: #fff;
        border: 1px solid #F06B64;
        &:hover {
          border: 1px solid #fff;
        }
      }
      .cancel {
        background-color: #fff;
        color: #F06B64;
        border: 1px solid #F06B64;
        &:hover {
          border: 1px solid #F06B64;
        }
      }
    }
  }
  .AddTable {
    overflow-y: auto;
    height: 200px;
    ${LectureViewTable} {
      .student {
        &:hover {
          background-color: #f78b8530 !important;
          cursor: pointer;
        }
      }
    }
  }
`
export const IsAddBtn = styled.button`
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1px solid #F6F1F1;
  text-align: center;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  &:hover {
    background-color: #f78b8530;
  }
`