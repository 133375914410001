import React, { useState } from 'react'
import NavLeft from '../NavigationContent/NavLeft'
import { Nav } from './styles'
import NavRight from '../NavigationContent/NavRight'

export const Navigation = ({ setMenu, setOpenSide, setIsEdit, menu }) => {
  return (
    <Nav>
      <NavLeft setOpenSide={setOpenSide} setMenu={setMenu} menu={menu}/>
      <NavRight setIsEdit={setIsEdit} setMenu={setMenu}/>
    </Nav>
  )
}
