import React from 'react';
import { LectureViewHeader, LectureViewTable } from '../Lecture/styles';
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList';

const TeacherTotalTable = ({ setIsTotal, setSelectTeacher, selectEmployee }) => {
  const { TeacherStudentData: DirectorList } = useTeacherStudent(1);
  const { TeacherStudentData: TeacherList } = useTeacherStudent(2);
  const { TeacherStudentData: DepartmentManager } = useTeacherStudent(4);

  let filteredList: any = [];

  switch (selectEmployee) {
    case '전체':
      filteredList = [
        ...(Array.isArray(TeacherList) ? TeacherList : []),
      ];
      break;
    case '원장':
      filteredList = Array.isArray(DirectorList) ? DirectorList : [];
      break;
    case '실장':
      filteredList = Array.isArray(DepartmentManager) ? DepartmentManager : [];
      break;
    case '강사':
      filteredList = Array.isArray(TeacherList) ? TeacherList : [];
      break;
    default:
      break;
  }

  return (
    <>
      <LectureViewHeader>
        <p>{selectEmployee}</p>
      </LectureViewHeader>
      <div className='scroll'>
        <LectureViewTable>
          <tbody>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th>과목</th>
              <th>담당수업</th>
              <th>아이디</th>
              <th>휴대폰번호</th>
              <th>비고</th>
            </tr>
          </tbody>
          <tbody>
            {filteredList?.length === 0 ? (
              <tr>
                <td colSpan={7}>선택한 직급에는 직원이 없습니다.</td>
              </tr>
            ) : (
              filteredList?.map((teacher, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td
                    style={{
                      textDecoration: 'underline',
                      textAlign: 'left',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsTotal(false);
                      setSelectTeacher(teacher.id);
                    }}
                  >
                    {teacher.name}
                  </td>
                  <td>{teacher.subjectList}</td>
                  <td className='charge'>
                    {teacher.classList?.map((subject, index) => (
                      <p key={index}>- {subject}</p>
                    ))}
                  </td>
                  <td>{teacher.email}</td>
                  <td>{teacher.phone}</td>
                  <td>{teacher.memo === '' ? '-' : teacher.memo}</td>
                </tr>
              ))
            )}
          </tbody>
        </LectureViewTable>
      </div>
    </>
  );
};

export default React.memo(TeacherTotalTable);
