import React, { useState } from 'react'
import { Blacked, Icon } from '../organism/Navigation/styles'
import { FaChevronLeft } from "react-icons/fa";
import { AddModalBtns, AddModalDisplay, AddModalHeader, AddModalMain, AddModalTable, Area, Section, TableMain, TableTitle } from './styles/addModalStyles'
import { message } from 'antd';
import { useToken } from 'app/hooks';
import { useAcademyList } from '../hooks/AcademyList';
import API from 'utils/api';

export const AddAcademyModal = ({ setIsAddAcademy }) => {
  const { data:token } = useToken();
  const { mutate:AcademyListMutate } = useAcademyList();
  const today = new Date().toISOString().split('T')[0]; 

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [academy, setAcademy] = useState('');
  const [director, setDirector] = useState('');
  const [phone, setPhone] = useState('');
  const [startDate, setStartDate] = useState(today);
  const [address, setAddress] = useState('');
  const [note, setNote] = useState('');

  const saveAcademy = (e) => {
    setAcademy(e.target.value);
  }
  const saveDirector = (e) => {
    setDirector(e.target.value);
  }
  const savePhone = (e) => {
    setPhone(e.target.value);
  }
  const saveStartDate = (e) => {
    setStartDate(e.target.value);
  }
  const saveAddress = (e) => {
    setAddress(e.target.value);
  }
  const saveNote = (e) => {
    setNote(e.target.value);
  }

  const handleSave = () => {
    if(email == '') {
      message.error('아이디를 입력해주세요.')
    } else if (password == '') {
      message.error('비밀번호를 입력해주세요.')
    } else if (academy == '') {
      message.error('학원명을 입력해주세요.')
    } else if (director == '') {
      message.error('원장명을 입력해주세요.')
    } else if (address == '') {
      message.error('주소를 입력해주세요.')
    }
    API.post(`/branch`, token + '', {
      email: email,
      password: password,
      name: academy,
      representativeName: director,
      tel: phone,
      memo: note,
      address: address,
      createdAt: startDate,
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('등록되었습니다');
        AcademyListMutate();
        setIsAddAcademy(false)
      }
    })
    .catch((err: any) => {})
  }

  return (
    <AddModalMain>
      <Blacked onClick={() => setIsAddAcademy(false)} />
      <AddModalDisplay>
        <AddModalHeader>
          <Icon onClick={() => setIsAddAcademy(false)}><FaChevronLeft /></Icon>
          <p>학원등록</p>
          <AddModalBtns>
            <button className='save' onClick={handleSave}>저장</button>
          </AddModalBtns>
        </AddModalHeader>
        <AddModalTable>
          <Area className='second'>
            <Section>
              <TableTitle>학원명 *</TableTitle>
              <TableMain>
                <input type='text' placeholder='학원명 작성' onChange={saveAcademy} />
              </TableMain>
            </Section>
            <Section>
              <TableTitle>대표원장 *</TableTitle>
              <TableMain>
                <input type='text' placeholder='원장명 작성' onChange={saveDirector} />
              </TableMain>
            </Section>
          </Area>
          <Area className='second'>
            <Section>
              <TableTitle>아이디 *</TableTitle>
              <TableMain>
                <input type='text' placeholder='아이디 작성' onChange={(e) => setEmail(e.target.value)} />
              </TableMain>
            </Section>
            <Section>
              <TableTitle>비밀번호 *</TableTitle>
              <TableMain>
                <input type='text' placeholder='비밀번호 작성' onChange={(e) => setPassword(e.target.value)} />
              </TableMain>
            </Section>
          </Area>
          <Area className='third'>
            <Section>
              <TableTitle>전화번호</TableTitle>
              <TableMain>
                <input type='text' placeholder='(-) 제외하고 작성' onChange={savePhone} />
              </TableMain>
            </Section>
            <Section>
              <TableTitle>등록일</TableTitle>
              <TableMain>
                <input type='date' value={startDate} onChange={saveStartDate} />
              </TableMain>
            </Section>
          </Area>
          <Area className='first'>
            <TableTitle>주소 *</TableTitle>
            <TableMain>
              <input type='text' placeholder='주소 작성' onChange={saveAddress} />
            </TableMain>
          </Area>
          <Area className='first'>
            <TableTitle>비고</TableTitle>
            <TableMain>
              <input type='text' placeholder='비고 작성' onChange={saveNote} />
            </TableMain>
          </Area>
        </AddModalTable>
      </AddModalDisplay>
    </AddModalMain>
  )
}

export default React.memo(AddAcademyModal)