import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/dashboard/lecture/' // (16) 학습일지 조회

let swrData: any = getPersistData(KEY)
let datefilter = ''

export function useLearningNote(lectureId: string) {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY + lectureId + `/report` + `?dateFilter=` + datefilter,
		async () => {
			const response = await API.get(KEY + lectureId + `/report` + `?dateFilter=` + datefilter, token + '', {})
			swrData = response.result?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		LearningNoteData: data,
		changeDateFilter: (value: string) => {
			datefilter = value
			mutate()
		},
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}

export function useManageLearningNote(lectureId: string, studentId: string) {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY + lectureId + `/report?studentId=` + studentId,
		async () => {
			const response = await API.get(
				KEY + lectureId + `/report?studentId=` + studentId + `&dateFilter=` + datefilter,
				token + '',
				{}
			)
			swrData = response.result?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		ManageLearningNoteData: data,
		changeDateFilter: (value: string) => {
			datefilter = value
			mutate()
		},
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
