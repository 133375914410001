import { message } from 'antd'
import AddTeacherModal from 'app/gomsacademy/components/AddTeacherModal'
import { useGetUserClass } from 'app/gomsacademy/hooks/GetClassList'
import { useGetSubject } from 'app/gomsacademy/hooks/GetSubjectList'
import { useGetUser } from 'app/gomsacademy/hooks/GetUser'
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList'
import { useToken } from 'app/hooks'
import React, { useEffect, useState } from 'react'
import { BsFillPencilFill } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa6'
import API from 'utils/api'
import { convertBinaryToDays } from 'utils/ciutils'
import { Icon } from '../Home/styles'
import {
	FixBtns,
	LectureListDisplay,
	LectureTotal,
	LectureView,
	LectureViewHeader,
	LectureWrapper,
	StudentViewTable,
} from '../Lecture/styles'
import TeacherList from './TeacherList'
import TeacherTotalTable from './TeacherTotalTable'

export const Teacher = ({ isWrite, setIsWrite, setLectureSt, setSelectLecture }) => {
	const { data: token } = useToken()
	const { TeacherStudentData: DirectorList, mutate: DirectorMutate } = useTeacherStudent(1)
	const { mutate: TeacherMutate } = useTeacherStudent(2)
	const { TeacherStudentData: DepartmentManager, mutate: DepartmentManagerMutate } =
		useTeacherStudent(4)
	const { SubjectList } = useGetSubject()
	const [selectTeacher, setSelectTeacher] = useState()
	const { UserData, mutate: userDataMutate } = useGetUser(selectTeacher)
	const { UserClassData } = useGetUserClass(selectTeacher)
	const [isTotal, setIsTotal] = useState(true)
	const [isAddTeacher, setIsAddTeacher] = useState(false)
	const [isFix, setIsFix] = useState(false)
	const [selectEmployee, setSelectEmployee] = useState('전체')
	console.log(UserData)

	const [fixName, setFixName] = useState(UserData?.name || '')
	const [fixEmail, setFixEmail] = useState(UserData?.email || '')
	const [fixPhone, setFixPhone] = useState(UserData?.phone || '')
	const [fixCreatedAt, setFixCreatedAt] = useState(UserData?.createdAt || '')
	const [fixMemo, setFixMemo] = useState(UserData?.memo || '')
	const [fixSubject, setFixSubject] = useState(0)

	const handleWriteClick = index => {
		setIsWrite(prevStates => {
			const newStates = [...prevStates]
			newStates[index] = !newStates[index]
			return newStates
		})
	}

	const DeleteTeacher = (userId, userName) => {
		const shouldDelete = window.confirm(`정말로 '${userName}' 강사를 삭제하시겠습니까?`)

		if (shouldDelete) {
			API.delete('/users/' + userId, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
						message.success('삭제되었습니다')
						TeacherMutate()
						setIsTotal(true)
					} else {
						if (res?.result?.message?.includes('foreign key')) {
							message.error('연결된 데이터가 있어 삭제할 수 없습니다.')
						} else {
							message.error(res?.result?.message || '삭제에 실패했습니다.')
						}
					}
				})
				.catch((err: any) => {})
		}
	}
	const SaveTeacher = userId => {
		API.put('/users/' + userId, token + '', {
			datas: {
				name: fixName,
				phone: fixPhone,
				createdAt: fixCreatedAt,
				email: fixEmail,
				subjectIds: [fixSubject],
				memo: fixMemo,
			},
		})
			.then((res: any) => {
				if (res.result.success) {
					message.success('수정되었습니다')
					userDataMutate()
					setIsFix(false)
				}
			})
			.catch((err: any) => {})
	}

	const handleClickOutsideSelect = event => {
		if (!event.target.closest('.selectTeacher')) {
			setIsFix(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideSelect)
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideSelect)
		}
	}, [])

	return (
		<LectureWrapper>
			<TeacherList
				setIsAddTeacher={setIsAddTeacher}
				setIsTotal={setIsTotal}
				selectTeacher={selectTeacher}
				setSelectTeacher={setSelectTeacher}
				selectEmployee={selectEmployee}
				setSelectEmployee={setSelectEmployee}
			/>
			<LectureView>
				{isTotal && (
					<TeacherTotalTable
						setIsTotal={setIsTotal}
						setSelectTeacher={setSelectTeacher}
						selectEmployee={selectEmployee}
					/>
				)}
				{isTotal == false && selectTeacher && (
					<div className="selectTeacher">
						<LectureViewHeader>
							<p>
								{!isFix ? (
									UserData?.name
								) : (
									<input
										className="fixName"
										type="text"
										placeholder={UserData?.name}
										onChange={e => setFixName(e.target.value)}
									/>
								)}
							</p>
							<FixBtns>
								{!isFix ? (
									<>
										<button className="fix" onClick={() => setIsFix(true)}>
											수정
										</button>
										<button
											className="delete"
											onClick={() => DeleteTeacher(UserData?.id, UserData?.name)}>
											삭제
										</button>
									</>
								) : (
									<>
										<button className="fix" onClick={() => SaveTeacher(UserData?.id)}>
											저장
										</button>
										<button className="delete" onClick={() => setIsFix(false)}>
											취소
										</button>
									</>
								)}
							</FixBtns>
						</LectureViewHeader>
						<StudentViewTable className="teacherTbody">
							<tbody>
								<tr>
									<th>과목</th>
									<th>아이디</th>
									<th>휴대폰번호</th>
									<th>수업과정</th>
									<th>등록일</th>
									<th>비고</th>
								</tr>
								<tr>
									<td>
										{!isFix ? (
											UserData?.subjectNames
										) : (
											<select onChange={e => setFixSubject(parseInt(e.target.value))}>
												<option>과목선택</option>
												{SubjectList.map((it, id) => (
													<option value={it.id}>{it.name}</option>
												))}
											</select>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.email
										) : (
											<input
												type="text"
												placeholder={UserData?.email}
												onChange={e => setFixEmail(e.target.value)}
											/>
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.phone
										) : (
											<input
												type="text"
												placeholder={UserData?.phone}
												onChange={e => setFixPhone(e.target.value)}
											/>
										)}
									</td>
									<td>{UserData?.classCount} 과정</td>
									<td>
										{!isFix ? (
											UserData?.createdAt
										) : (
											<input type="date" onChange={e => setFixCreatedAt(e.target.value)} />
										)}
									</td>
									<td>
										{!isFix ? (
											UserData?.memo == '' ? (
												'-'
											) : (
												UserData?.memo
											)
										) : (
											<input
												type="text"
												placeholder={UserData?.memo}
												onChange={e => setFixMemo(e.target.value)}
											/>
										)}
									</td>
								</tr>
							</tbody>
						</StudentViewTable>
						{/* ===== 모바일 일 때 ===== */}
						<LectureListDisplay className="teacherMobile">
							<div className="mobileTable">
								<div className="tableRow">
									<div className="tableEl">
										<p className="th">과목</p>
										<p className="td">{UserData?.subjectNames}</p>
									</div>
									<div className="tableEl">
										<p className="th">권한</p>
										<p className="td">{UserData?.authority}</p>
									</div>
								</div>
								<div className="tableRow">
									<div className="tableEl">
										<p className="th">수업과정</p>
										<p className="td">{UserData?.classCount} 과정</p>
									</div>
									<div className="tableEl">
										<p className="th">등록일</p>
										<p className="td">{UserData?.createdAt}</p>
									</div>
								</div>
								<div className="tableRow">
									<p className="th">아이디</p>
									<p className="td">{UserData?.email}</p>
								</div>
								<div className="tableRow">
									<p className="th">휴대폰번호</p>
									<p className="td">{UserData?.phone}</p>
								</div>
								<div className="tableRow">
									<p className="th">비고</p>
									<p className="td">{UserData?.memo == '' ? '-' : UserData?.memo}</p>
								</div>
							</div>
						</LectureListDisplay>
						<StudentViewTable>
							<tbody className="teacherTbody">
								<tr>
									<th className="no">No.</th>
									<th>강의</th>
									<th className="school">학교</th>
									<th>요일</th>
									<th>시간</th>
									<th>방식</th>
									<th>개강일</th>
									<th>종강일</th>
								</tr>
								{UserClassData?.map((it, idx) => (
									<tr key={idx}>
										<td className="no">{idx + 1}</td>
										<td className="lecture">
											{it.name}
											{it.type == '강의식' && (
												<button
													className="manageNoteBtn"
													onClick={() => {
														handleWriteClick(idx)
														setSelectLecture(it)
														setLectureSt(true)
													}}
													style={{
														border: '1px solid #EFA44F',
														backgroundColor: isWrite[idx] ? '#EFA44F' : 'transparent',
														color: isWrite[idx] ? '#FFF' : '#EFA44F',
													}}>
													<Icon>{isWrite[idx] ? <FaCheck /> : <BsFillPencilFill />}</Icon>
													<p style={{ fontSize: '14px' }}>학습일지</p>
												</button>
											)}
										</td>
										<td className="school">{it.schoolType}</td>
										<td>{convertBinaryToDays(it.days)}</td>
										<td>
											{it.time == '' || it.time == '00:00'
												? '-'
												: it?.time?.slice(3, 5) === '00'
												  ? it?.time?.slice(0, 2) + '시간'
												  : it?.time?.slice(0, 2) + '시간' + it?.time?.slice(3, 5) + '분'}
										</td>
										<td>{it.type}</td>
										<td>{it?.startedAt?.slice(0, 10)}</td>
										<td className="">{it.endedAt == '' ? '-' : it?.endedAt?.slice(0, 10)}</td>
									</tr>
								))}
							</tbody>
						</StudentViewTable>
						<div className="teacherMobile">
							{UserClassData?.map((it, idx) => (
								<LectureListDisplay>
									<LectureTotal style={{}}>
										<p>{it.lecture}</p>
										{it.type == '강의식' && (
											<button
												className="manageNoteBtn"
												onClick={() => {
													handleWriteClick(idx)
													setSelectLecture(it)
													setLectureSt(true)
												}}
												style={{
													border: '1px solid #EFA44F',
													backgroundColor: isWrite[idx] ? '#EFA44F' : '#fff',
													display: 'flex',
													alignItems: 'center',
													borderRadius: '4px',
													padding: '5px 8px',
												}}>
												<p style={{ fontSize: '14px', color: isWrite[idx] ? '#FFF' : '#EFA44F' }}>
													학습일지
												</p>
											</button>
										)}
									</LectureTotal>
									<div className="mobileTable">
										<div className="tableRow">
											<div className="tableEl">
												<p className="th">학교</p>
												<p className="td">{it.schoolType}</p>
											</div>
											<div className="tableEl">
												<p className="th">요일</p>
												<p className="td">{convertBinaryToDays(it.days)}</p>
											</div>
										</div>
										<div className="tableRow">
											<div className="tableEl">
												<p className="th">방식</p>
												<p className="td">{it.type}</p>
											</div>
											<div className="tableEl">
												<p className="th">시간</p>
												<p className="td">{it.time}</p>
											</div>
										</div>
										<div className="tableRow">
											<div className="tableEl">
												<p className="th">개강일</p>
												<p className="td">{it?.startedAt?.slice(0, 10)}</p>
											</div>
											<div className="tableEl">
												<p className="th">종강일</p>
												<p className="td">{it.endedAt == '' ? '-' : it?.endedAt?.slice(0, 10)}</p>
											</div>
										</div>
									</div>
								</LectureListDisplay>
							))}
						</div>
					</div>
				)}
			</LectureView>
			{isAddTeacher && <AddTeacherModal setIsAddTeacher={setIsAddTeacher} />}
		</LectureWrapper>
	)
}

export default React.memo(Teacher)
