import React, { useEffect, useRef, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { Blacked, Icon, ManageAcademy, MobileSideDisplay, MobileSideMain, NavInput, ResultArea, SideBtns, SideHeader, SideInputArea, SideSearch } from '../Navigation/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetMyInfo } from 'app/gomsacademy/hooks/GetMyInfo';
import { useSearch } from 'app/gomsacademy/hooks/SearchBar';

export const MobileSide = ({ menu, setMenu, openSide, setOpenSide }) => {
  const navigate = useHistory();
  const location = useLocation()
  const { pathname } = location;
  const current = pathname.split("/admin/academy/")[1]

  const { myInfo } = useGetMyInfo();
  const [keyword, setKeyword] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const { SearchResult } = useSearch(keyword);
  const searchAreaRef = useRef<HTMLDivElement | null>(null);

  const handleBtnClick = (menu) => {
    navigate.push('/admin/academy/' + menu)
    setMenu(menu)
  };

  const handleIsSearch = () => {
    setIsSearch(!isSearch);
  };

  const clickResultLecture = (it) => {
    navigate.push(`/admin/academy/lecture?id=${it}`);
    setMenu('lecture');
    setOpenSide(false);
  }
  const clickResultStudent = (it) => {
    navigate.push(`/admin/academy/student?id=${it}`);
    setMenu('student');
    setOpenSide(false);
  }

  const handleClickOutsideSelect = (event) => {
    if (!event.target.closest('.resultArea')) {
      setIsSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSelect);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSelect);
    };
  }, []);

  return (
    <>
      {openSide && 
        <MobileSideMain>
          <Blacked onClick={() => setOpenSide(false)} />
          <MobileSideDisplay>
            <SideHeader>
              {!myInfo?.master ? (
                <>
                  <p>{myInfo.name}</p>
                  <button className='logout'>로그아웃</button>
                </>
              ) : (
                <>
                  <>
                    <p>{myInfo.name}</p>
                    <ManageAcademy 
                      style={{marginLeft: '-50px'}} 
                      onClick={() => handleBtnClick(current !== 'academy' ? 'academy' : '')}
                    >
                      {current !== 'academy' ? '지점관리' : '학원관리'}
                    </ManageAcademy>
                  </>
                  <button className='logout'>로그아웃</button>
                </>
              )}
            </SideHeader>
            <SideSearch ref={searchAreaRef}>
              <SideInputArea>
                <NavInput
                  type='text'
                  placeholder='학생, 강의 검색'
                  onChange={(e) => setKeyword(e.target.value)}
                  onFocus={() => setIsSearch(true)}
                />
                <Icon onClick={handleIsSearch}><CiSearch /></Icon>
              </SideInputArea>
              <ResultArea className='resultArea' style={{ display: isSearch ? 'block' : 'none', top: '100px', zIndex:'999', width: '90%' }}>
                {keyword !== '' && (
                  <div>
                    {SearchResult?.lectureList?.list?.map((it, idx) => (
                      <p key={idx} onClick={() => clickResultLecture(it.id)}>
                        {it.name}
                        <span 
                          style={{backgroundColor: '#DAF5E580', color: '#056B2E'}}
                        >
                          강의
                        </span>
                      </p>
                    ))}
                    {SearchResult?.studentList?.list?.map((it, idx) => (
                      <p key={idx} onClick={() => clickResultStudent(it.id)}>
                        {it.name}
                        <span 
                          style={{backgroundColor: '#FFE7CB80', color: '#D3490D'}}
                        >
                          학생
                        </span>
                      </p>
                    ))}
                  </div>
                )}
              </ResultArea>
            </SideSearch>
            {current !== 'academy' ? (
              <SideBtns>
                <button onClick={() => handleBtnClick('')} className={menu === '' ? 'selected' : ''}>홈</button>
                <button onClick={() => handleBtnClick('lecture')} className={menu === 'lecture' ? 'selected' : ''}>강의관리</button>
                <button onClick={() => handleBtnClick('student')} className={menu === 'student' ? 'selected' : ''}>학생관리</button>
                <button onClick={() => handleBtnClick('teacher')} className={menu === 'teacher' ? 'selected' : ''}>강사관리</button>
                <button onClick={() => handleBtnClick('attendance')} className={menu === 'attendance' ? 'selected' : ''}>출결확인</button>
              </SideBtns>
            ): (
              <SideBtns>
                <button onClick={() => handleBtnClick('academy')} className={menu === 'academy' ? 'selected' : ''}>학원관리</button>
                <button onClick={() => handleBtnClick('note')} className={menu === 'note' ? 'selected' : ''}>오답노트</button>
              </SideBtns>
            )}
          </MobileSideDisplay>
        </MobileSideMain>
      }
    </>
  )
}

export default React.memo(MobileSide);