import React, { useState } from 'react'
import {
	LectureGrade,
	LectureListDisplay,
	LectureListHeader,
	LectureListWrapper,
	LectureTotal,
	LectureViewTable,
	LectureWrapper,
} from '../Lecture/styles'
import { useAcademyList } from '../../hooks/AcademyList'
import { useUserList } from '../../hooks/GetUserList'
import AddNoteUserModal from '../../components/AddNoteUserModal'
import API from '../../../../utils/api'
import { message } from 'antd'
import { useToken } from '../../../hooks'

export const Note = () => {
	const { data, mutate } = useUserList(5)
	const { data: token } = useToken()
	const [isAddAcademy, setIsAddAcademy] = useState(false)

	return (
		<LectureWrapper>
			<LectureListWrapper>
				<LectureListHeader>
					<p>오답노트 계정</p>
					<button
						onClick={() => {
							setIsAddAcademy(true)
						}}>
						계정 생성
					</button>
				</LectureListHeader>
			</LectureListWrapper>

			<LectureListDisplay
				style={{
					width: '100%',
				}}>
				<LectureTotal onClick={() => {}}>
					<p>전체</p>
					<span>0</span>
				</LectureTotal>

				<LectureViewTable>
					<tbody>
						<tr>
							<th>사용자명</th>
							<th style={{ minWidth: '60px' }}>아이디</th>
							<th>휴대폰</th>
							<th>등록일</th>
							<th>비고</th>
						</tr>
						{data?.length === 0 && (
							<tr>
								<td colSpan={5}>데이터가 없습니다</td>
							</tr>
						)}
						{data?.map((item, index) => {
							console.log(item)
							return (
								<tr key={index}>
									<td>{item?.name}</td>
									<td>{item?.email}</td>
									<td>{item?.phone}</td>
									<td>{item?.created_at?.split('T')[0]}</td>
									<td>
										<button
											onClick={() => {
												API.delete(`/users/${item?.id}`, token + '', {
													deptIdForce: 0,
												}).then((res: any) => {
													if (res.result.success) {
														message.success('삭제되었습니다')
													} else {
														if (res?.result?.message?.includes('foreign key')) {
															message.error('연결된 데이터가 있어 삭제할 수 없습니다.')
														} else {
															message.error(res?.result?.message || '삭제에 실패했습니다.')
														}
													}
													mutate()
												})
											}}>
											삭제
										</button>
									</td>
								</tr>
							)
						})}
					</tbody>
				</LectureViewTable>
			</LectureListDisplay>

			{isAddAcademy && <AddNoteUserModal setIsAddAcademy={setIsAddAcademy} />}
		</LectureWrapper>
	)
}

export default React.memo(Note)
