import React from 'react'
import { LectureViewHeader, LectureViewTable } from '../Lecture/styles'
import { useLectureList } from 'app/gomsacademy/hooks/LectureList'
import { convertBinaryToDays } from 'utils/ciutils'

export const TotalTable = ({ setIsTotal, setSelectLecture, selectGrade }) => {
	const { LectureListData: lectureAll } = useLectureList(0)

	const sortBySchoolTypeAndName = (a, b) => {
		const order = ['N수', '고', '중', '초']

		const indexOfA = order.indexOf(a.schoolType)
		const indexOfB = order.indexOf(b.schoolType)
		const schoolTypeComparison = indexOfA - indexOfB
		if (schoolTypeComparison !== 0) {
			return schoolTypeComparison
		}
		return a.name.localeCompare(b.name)
	}

	const sortedLectureAll = lectureAll?.slice().sort(sortBySchoolTypeAndName)

	const filterLecture = schoolType => lectureAll?.filter(it => it?.schoolType === schoolType)

	const renderLectureRows = lectures =>
		lectures?.map((it, idx) => (
			<tr key={idx}>
				<td>{idx + 1}</td>
				<td
					className="lecture"
					onClick={() => {
						setIsTotal(false)
						setSelectLecture(it)
					}}
					style={{ textDecoration: 'underline', cursor: 'pointer' }}>
					{it.name}
				</td>
				<td>{it.schoolType}</td>
				<td>{convertBinaryToDays(String(it.days))}</td>
				<td className="time">
					{it.time == '' || it.time == '00:00'
						? '-'
						: it?.time?.slice(3, 5) === '00'
						  ? it?.time?.slice(0, 2) + '시간'
						  : it?.time?.slice(0, 2) + '시간' + it?.time?.slice(3, 5) + '분'}
				</td>
				<td>{it.teacherNames?.length == 1 ? it.teacherNames : it.teacherNames?.join(', ')}</td>
				<td>{it.type}</td>
				<td>{it.startedAt}</td>
				<td>{it.endedAt == '' ? '-' : it.endedAt}</td>
				<td className="person">
					<span>{it.totalCount == null ? '0' : it.totalCount}</span> / {it.maxCount}
				</td>
			</tr>
		))

	const LectureGroups = {
		전체: lectureAll,
		무학년: filterLecture('무학년'),
		N수: filterLecture('N수'),
		고: filterLecture('고'),
		중: filterLecture('중'),
		초: filterLecture('초'),
	}

	const selectedLectures = LectureGroups[selectGrade] || []

	return (
		<>
			<LectureViewHeader>
				<p>{selectGrade}</p>
			</LectureViewHeader>
			<div className="scroll">
				<LectureViewTable className="lectureTotal">
					<tbody>
						<tr>
							<th>No.</th>
							<th>강의</th>
							<th>대상</th>
							<th>요일</th>
							<th className="time">학습시간</th>
							<th>강사</th>
							<th>방식</th>
							<th>개강일</th>
							<th>종강일</th>
							<th>정원</th>
						</tr>
					</tbody>
					<tbody>
						{selectedLectures.length === 0 ? (
							<tr>
								<td colSpan={11} style={{ textAlign: 'center', padding: '20px 0' }}>
									선택한 강의에는 학생이 없습니다.
								</td>
							</tr>
						) : (
							renderLectureRows(selectedLectures)
						)}
					</tbody>
				</LectureViewTable>
			</div>
		</>
	)
}

export default React.memo(TotalTable)
