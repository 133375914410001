import styled from "styled-components";
import './font.css'

export const Main = styled.div`
  font-family: 'Pretendard-Regular';
  background-color: #FAFAFA;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  > * > input {
    &:focus {
      outline: #F78B85;
    }
  }
`