import { Blacked, Icon } from "app/gomsacademy/organism/Navigation/styles";
import styled from "styled-components";

export const AddModalMain = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  min-width: 360px;
  @media (max-width: 1023px) {
    ${Blacked} {
      display: none;
    }
  }
`
export const AddModalDisplay = styled.div`
  z-index: 999;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -42%);
  width: 44%;
  height: 410px;
  background-color: #fff;
  border-radius: 4px;
  padding: 36px 24px;
  @media (max-width: 1800px) {
    width: 46%;
  }
  @media (max-width: 1700px) {
    width: 50%;
  }
  @media (max-width: 1600px) {
    width: 60%;
  }
  @media (max-width: 1300px) {
    width: 66%;
  }
  @media (max-width: 1200px) {
    width: 72%;
  }
  @media (max-width: 1100px) {
    width: 77%;
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  @media (max-width: 400px) {
    padding: 36px 6px;
  }
`
export const AddModalHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
  p {
    color: #111111;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -2px;
    text-align: center;
  }
  ${Icon} {
    position: absolute !important;
    left: 24px;
    top: 10px;
    color: #666666;
    scale: 1;
    cursor: pointer;
  }
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    gap: 15px;
    p {
      font-size: 18px;
    }
  }
`
export const AddModalBtns = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  .delete {
    width: 60px;
    border: 1px solid #525252;
    padding: 8px 10px;
    border-radius: 4px;
    background-color: transparent;
  }
  .save {
    width: 100px;
    border: 1px solid #FD9C97;
    padding: 8px 10px;
    border-radius: 4px;
    color: #fff;
    background-color: #F06B64;
    box-shadow: 0px 0px 20px 0px #78161626 inset;
  }
  .edit {
    /* width: 100px; */
    border: 1px solid #FD9C97;
    padding: 8px 10px;
    border-radius: 4px;
    color: #F06B64;
    background-color: transparent;
  }
  .excelDown {
    width: 140px;
    background-color: transparent;
    border: 1px solid #525252;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 13px;
  }
  .excel {
    background-color: transparent;
    border: 1px solid #525252;
    padding: 10px;
    border-radius: 4px;
    font-size: 13px;
    color: #666666;
  }
  .excelInput {
    border: 1px solid #FD9C97;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    background-color: #F06B64;
    box-shadow: 0px 0px 20px 0px #78161626 inset;
    color: #fff;
    font-size: 13px;
    &:hover {
      background-color: #fdada9;
    }
  }
  input[type=file] {
    /* display: none; */
  }
  @media (max-width: 1200px) {
    .save {
      width: 60px;
    }
  }
  @media (max-width: 1024px) {
    .excel {
      display: none;
    }
  }
`
export const TableTitle = styled.span`
  width: 120px;
  text-align: center;
  color: #666666;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -1px;
  background-color: #FFF5F5;
  padding: 16px;
  border-right: 1px solid #F6F1F1;
  border-bottom: 1px solid #F6F1F1;
`
export const TableMain = styled.div`
  padding: 0 10px;
  width: 70%;
  > select, input {
    padding: 4px 10px;
    width: 100%;
    border: 1px solid #E9E9E9;
  }
  input::placeholder {
    color: #C0C0C0;
    font-size: 15px;
  }
  
`
export const AddModalTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #F6F1F1;
  border-bottom: none;
  .first {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #F6F1F1;
    ${TableTitle} {
      border-bottom: none;
    }
  }
`
export const Area = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`
export const Section = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F6F1F1;
  .date {
    display: flex;
    align-items: center;
    gap: 6px;
    p {
      background-color: #F3F3F3;
      padding: 4px 7px;
      border-radius: 2px;
      color: #666666;
      font-size: 16px;
      font-weight: 400;
      &:hover {
        background-color: #F78B85;
        color: #fff;
      }
      &.selected {
        background-color: #F78B85;
        color: #fff;
      }
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 422px) {
    .date {
      gap: 2px;
    }
  }
`