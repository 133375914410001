import React, { useState } from 'react'
import {
	AttendanceDisplay,
	AttendanceHeader,
	AttendanceList,
	AttendanceWrapper,
	HomeWrapper,
	IsSelectLecture,
	LectureHeader,
	NotSelectLecture,
	SelectLecture,
	Table,
	Title,
	TodayLecture,
} from '../Home/styles'
import { useLecture } from 'app/gomsacademy/hooks/TodayLecture'
import { useLectureStudent } from 'app/gomsacademy/hooks/TodayLectureStudent'
import { useGetAttendance } from 'app/gomsacademy/hooks/GetAttendance'
import API from 'utils/api'
import { useToken } from 'app/hooks'
import { message } from 'antd'
import { FaCheck } from 'react-icons/fa6'

export const Attendance = ({ selectLecture, setSelectLecture }) => {
	const { data: token } = useToken()
	const { LectureData, mutate: LectureListMutate } = useLecture()
	const { LectureStudentData, mutate: LectureStudentMutate } = useLectureStudent(selectLecture?.id)
	const { AttendanceData } = useGetAttendance(selectLecture?.id)

	const [isSend, setIsSend] = useState<{ lecture: number; student: number; value: boolean }[]>([])

	const sortBySchoolTypeAndName = (a, b) => {
		const order = ['N수', '고', '중', '초']
		const indexOfA = order.indexOf(a.schoolType)
		const indexOfB = order.indexOf(b.schoolType)
		const schoolTypeComparison = indexOfA - indexOfB
		if (schoolTypeComparison !== 0) {
			return schoolTypeComparison
		}
		return a.name.localeCompare(b.name)
	}
	const sortedLectureData = LectureData?.slice().sort(sortBySchoolTypeAndName).reverse()

	const handleTableClick = it => {
		if (selectLecture?.name == it?.name) {
			setSelectLecture(null)
		} else {
			setSelectLecture(it)
		}
	}

	const sendMessageAttendance = studentId => {
		if (window.confirm('문자전송을 하시겠습니까?')) {
			API.post('/dashboard/message', token + '', {
				type: 'absence',
				studentId: studentId,
				date: new Date().toISOString().split('T')[0]?.slice(0, 7),
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('문자전송이 완료되었습니다')
					} else {
						message.error(res?.result?.message || '문자전송에 실패했습니다.')
					}
				})
				.catch((err: any) => {})
		}
	}

	const handleSendClick = index => {
		setIsSend(prevStates => {
			const newStates = prevStates.map(item => ({
				lecture: item.lecture,
				student: item.student,
				value:
					item.lecture === index.lecture && item.student === index.student
						? !item.value
						: item.value,
			}))

			// If the index doesn't exist in the current state, add it
			if (
				!newStates.some(item => item.lecture === index.lecture && item.student === index.student)
			) {
				newStates.push({ lecture: index.lecture, student: index.student, value: true })
			}

			return newStates
		})
	}

	return (
		<HomeWrapper>
			<TodayLecture>
				<Title>
					금일강의리스트
					<span>{LectureData?.length}</span>
				</Title>
				<Table>
					<tbody>
						<th>No.</th>
						<th>시간</th>
						<th>강의</th>
						<th>방식</th>
						<th>학년</th>
						<th>강사</th>
						<th className="person">정원</th>
						{sortedLectureData?.map((it, idx) => (
							<tr
								key={idx}
								onClick={() => handleTableClick(it)}
								style={{
									backgroundColor: selectLecture?.name == it.name ? '#F78B8580' : '',
								}}>
								<td>{idx + 1}</td>
								<td>
									{it.time == '' || it.time == '00:00'
										? '-'
										: it.time?.slice(3, 5) === '00'
										  ? it.time?.slice(0, 2) + '시간'
										  : it.time?.slice(0, 2) + '시간' + it.time?.slice(3, 5) + '분'}
								</td>
								<td style={{ textAlign: 'left' }}>{it.name}</td>
								<td>{it.type}</td>
								<td>{it.grade}</td>
								<td>
									{it.teacherNames.map(it => (
										<p>{it}</p>
									))}
								</td>
								<td className="person">
									<span>{it.totalCount == null ? '0' : it.totalCount}</span> / {it.maxCount}
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</TodayLecture>
			<SelectLecture>
				{!selectLecture ? (
					<NotSelectLecture>
						<p>강의를 선택해주세요.</p>
					</NotSelectLecture>
				) : (
					<IsSelectLecture>
						<LectureHeader style={{ justifyContent: 'center' }}>
							<div className="title">
								<p>{selectLecture.name}</p>
								<span>{LectureStudentData?.length}명</span>
							</div>
						</LectureHeader>
						<AttendanceWrapper style={{ display: 'flex' }}>
							<AttendanceDisplay style={{ width: '50%' }}>
								<AttendanceHeader>
									<span>결시</span>
									<span style={{ color: '#222' }}>
										{AttendanceData?.filter(it => it.attendanceStatus == 0)?.length} 명
									</span>
								</AttendanceHeader>
								<AttendanceList>
									{AttendanceData?.filter(it => it.attendanceStatus == 0)?.map(
										(it, idx) =>
											it.name !== null && (
												<div>
													<span>{it.name}</span>
													<button onClick={() => sendMessageAttendance(it.userId)}>
														{isSend.find(
															entry =>
																entry.lecture === selectLecture?.id && entry.student === it.userId
														)?.value ? (
															<FaCheck />
														) : (
															'문자전송'
														)}
													</button>
												</div>
											)
									)}
								</AttendanceList>
							</AttendanceDisplay>
							<AttendanceDisplay style={{ width: '50%', borderLeft: '1px solid #F6F1F1' }}>
								<AttendanceHeader>
									<span>등원</span>
									<span style={{ color: '#222' }}>
										{AttendanceData?.filter(it => it.attendanceStatus == 1)?.length} 명
									</span>
								</AttendanceHeader>
								<AttendanceList>
									{AttendanceData?.filter(it => it.attendanceStatus == 1)?.map(
										(it, idx) =>
											it.name !== null && (
												<div>
													<span>{it.name}</span>
												</div>
											)
									)}
								</AttendanceList>
							</AttendanceDisplay>
						</AttendanceWrapper>
					</IsSelectLecture>
				)}
			</SelectLecture>
		</HomeWrapper>
	)
}

export default React.memo(Attendance)
