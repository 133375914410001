import React, { useState } from 'react'
import { AddModalBtns, AddModalDisplay, AddModalHeader, AddModalMain, AddModalTable, Area, Section, TableMain, TableTitle } from './styles/addModalStyles'
import { Blacked, Icon } from '../organism/Navigation/styles'
import { FaChevronLeft } from "react-icons/fa";
import { useGetMyInfo } from '../hooks/GetMyInfo';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import { message } from 'antd';

export const EditInfo = ({ setIsEdit }) => {
  const { data: token } = useToken();
  const { myInfo, mutate: myInfoMutate } = useGetMyInfo();
  const [isFix, setIsFix] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [fixName, setFixName] = useState(myInfo?.name || '');
  const [fixPhone, setFixPhone] = useState(myInfo?.phone || '');
  const [fixEmail, setFixEmail] = useState(myInfo?.email || '');
  const [fixAddress, setFixAddress] = useState(myInfo?.address || '');

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleSave = () => {
    API.put(`/user/my/info`, token + '', {
      datas: {
        phone: fixPhone,
        name: fixName,
        email: fixEmail,
      }
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('개인정보가 변경되었습니다');
        myInfoMutate();
        setIsEdit(false)
      }
    })
    .catch((err: any) => {})
  }
  const savePassword = () => {
    API.post(`/auth/signin`, token + '', {
      email: myInfo.email,
      password: password,     
    })
    .then((res: any) => {
      if (res.result.success) {
        API.put(`/user/my/password`, token + '', {
          password: newPassword,      
        })
        .then((res: any) => {
          if (res.result.success) {
            message.success('비밀번호가 변경되었습니다.')
            myInfoMutate();
            setEditPassword(false)
          }
        })
        .catch((err: any) => {})
      }
    })
    .catch((err: any) => {
      message.error('기존 비밀번호가 틀렸습니다.')
    })
  }
  const clickCancel = () => {
    setFixName('')
    setFixPhone('')
    setFixAddress('')
    setFixEmail('')
    setIsFix(false)
  }
  
  return (
    <AddModalMain>
      <Blacked onClick={() => setIsEdit(false)} />
      <AddModalDisplay>
        <AddModalHeader>
          <Icon onClick={() => setIsEdit(false)}><FaChevronLeft /></Icon>
          <p>회원정보 수정</p>
          <AddModalBtns>
            {!isFix ? (
              <>
                <button className='edit' onClick={() => setIsFix(true)}>수정</button>
                <button style={{width: 'auto'}} className='save' onClick={() => setIsEdit(false)}>취소</button>
              </>
            ) : (
              <>
                <button className='edit' onClick={clickCancel}>취소</button>
                <button style={{width: 'auto'}} className='save' onClick={handleSave}>저장</button>
              </>
            )}
          </AddModalBtns>
        </AddModalHeader>
        <AddModalTable>
          <Area className='second'>
            <Section>
              <TableTitle>이름</TableTitle>
              <TableMain>
                {!isFix ? (
                  <p>{myInfo.name}</p>
                ) : (
                  <input type='text' placeholder={`${myInfo.name}`} onChange={(e) => setFixName(e.target.value)} />
                )}
              </TableMain>
            </Section>
            <Section>
              <TableTitle>전화번호</TableTitle>
              <TableMain>
                {!isFix ? (
                  <p>{myInfo.phone}</p>
                ) : (
                  <input type='text' placeholder={`${myInfo.phone}`} onChange={(e) => setFixPhone(e.target.value)} />
                )}
              </TableMain>
            </Section>
          </Area>
          <Area className='first'>
            <Section>
              <TableTitle>이메일</TableTitle>
              <TableMain>
                {!isFix ? (
                  <p>{myInfo.email}</p>
                ) : (
                  <input type='text' placeholder={`${myInfo.email}`} />
                )}
              </TableMain>
            </Section>
          </Area>
          <Area className='first'>
            <Section>
              <TableTitle>비밀번호</TableTitle>
              <TableMain>
                {!editPassword ? (
                  <button 
                    style={{
                      border: '1px solid #676767', 
                      backgroundColor: '#222222', 
                      color: '#fff', 
                      borderRadius: '4px',
                      padding: '6px 8px'
                    }} 
                    onClick={() => setEditPassword(true)}
                  >
                    비밀번호 변경
                  </button>
                ) : (
                  <input type='password' placeholder='기존 비밀번호 입력' autoFocus onChange={(e) => setPassword(e.target.value)} />
                )}
              </TableMain>
            </Section>
          </Area>
          {editPassword && (
            <Area className='first'>
              <Section>
                <TableTitle>새 비밀번호 입력</TableTitle>
                <TableMain>
                  <input type='password' placeholder='새 비밀번호 입력' onChange={(e) => setNewPassword(e.target.value)} />
                </TableMain>
              </Section>
              {!editPassword ? (
                <></>
              ) : (
                <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                  <button 
                    style={{
                      border: '1px solid #C74C45', 
                      backgroundColor: '#F78B85', 
                      color: '#fff', 
                      borderRadius: '4px',
                      padding: '6px 8px'
                    }} 
                    onClick={savePassword}
                  >
                    변경
                  </button>
                  <button 
                    style={{
                      border: '1px solid #676767', 
                      backgroundColor: '#222222', 
                      color: '#fff', 
                      borderRadius: '4px',
                      padding: '6px 8px'
                    }}
                    onClick={() => {setEditPassword(false); setNewPassword('')}}
                  >
                    취소
                  </button>
                </div>
              )}
            </Area>
          )}
        </AddModalTable>
      </AddModalDisplay>
    </AddModalMain>
  )
}

export default React.memo(EditInfo)