import React, { useEffect, useState } from 'react'
import { HomeWrapper, Title, TodayLecture, Table, SelectLecture, NotSelectLecture, IsSelectLecture, LectureHeader, Icon, Button, IsAdd, IsAddBtn } from './styles'
import { BsFillPencilFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { message } from 'antd';
import { useLecture } from 'app/gomsacademy/hooks/TodayLecture';
import { useLectureStudent } from 'app/gomsacademy/hooks/TodayLectureStudent';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import { CiSearch } from "react-icons/ci";
import { LectureListSearch, LectureViewTable } from '../Lecture/styles';
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList';
import { useGetMyInfo } from 'app/gomsacademy/hooks/GetMyInfo';
import { useLearningNote, useManageLearningNote } from 'app/gomsacademy/hooks/LearningNote';
import { useLectureStudents } from 'app/gomsacademy/hooks/LectureStudents';
import { useDashboardLecture } from '../../hooks/dashboardLecture';
import styled from "styled-components";
import {useMyLecture} from "../../hooks/TotalLecture";

export const Home = ({ 
    setSelectStudentNote, 
    setManageSt, 
    isWrite,
    setIsWrite, 
    setLectureSt,
    editNoteIndex,
    setEditNoteIndex,
    selectLecture,
    setSelectLecture
  }) => {
  const { data: token } = useToken();
  const { myInfo } = useGetMyInfo();
  const { LectureData, mutate: LectureListMutate } = useLecture();
  const { LectureMyData, mutate: LectureMyListMutate } = useMyLecture();
  const { TeacherStudentData: StudentAll } = useTeacherStudent(3);
  LectureListMutate()
  const { DashboardLectureStudentsData, mutate:DashboardLectureStudentMutate } = useDashboardLecture(selectLecture?.id);
  const [isCheck, setIsCheck] = useState<boolean[]>(Array(DashboardLectureStudentsData?.length).fill(false));
  const [fixMemo, setFixMemo] = useState({})
  const [isAdd, setIsAdd] = useState(false);
  const [AddStudent, setAddStudent] = useState<number[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [lectureType, setLectureType] = useState('today');


  // useEffect(() => {
  //   console.log('LectureData', LectureData)
  // }, [LectureData]);

  // useEffect(() => {
  //   console.log('LectureMyData', LectureMyData)
  // }, [LectureMyData]);

  const searchAddStudent = () => {
    return StudentAll?.filter(student => student.name.toLowerCase().includes(searchKeyword.toLowerCase()))
  }
  
  const handleTableClick = (it) => {
    if(selectLecture?.name == it?.name) {
      setSelectLecture(null);
    } else {
      setSelectLecture(it);
    }
  }
  const handleStudentNote = (it, idx) => {
    setSelectStudentNote(it);
    setManageSt(true);
    setEditNoteIndex(idx)
  }

  // ** 출석체크 =====
  const handleCheckClick = (studentId, attendanceStatus) => {
    API.put('/dashboard/lecture/' + selectLecture?.id + '/' + studentId, token + '', {
      status: attendanceStatus == 1 ? 0 : 1,
    })
    .then((res: any) => {
      if (res.result.success) {
        {attendanceStatus == 0 
          ? message.success('출석되었습니다') 
          : message.error('출석 취소되었습니다') 
        }
        DashboardLectureStudentMutate(attendanceStatus);
      }
    })
    .catch((err: any) => {})
  };

  // ** 비고란 더블클릭 후 벗어날때 저장 =====
  const handleDoubleClick = (studentId) => {
    setEditNoteIndex(studentId);
    setFixMemo((prevFixMemo) => ({
      ...prevFixMemo,
      [studentId]: DashboardLectureStudentsData?.find((student) => student.id === studentId)?.memo || '',
    }));
  };
  
  const handleOnBlur = (studentId) => {
    API.put('/dashboard/lecture/' + selectLecture?.id + '/' + studentId, token + '', {
      memo: fixMemo[studentId],
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('비고가 작성되었습니다')
        DashboardLectureStudentMutate();
        setFixMemo(fixMemo);
        setEditNoteIndex(null);
      }
    })
    .catch((err: any) => {})
  };

  

  // ** 선택한 학생들 강의에 등록 =====
  const clickAddStudent = () => {
    const duplicateStudents = AddStudent.filter(studentId =>
      DashboardLectureStudentsData?.some(student => student.id === studentId)
    );
  
    if (duplicateStudents.length > 0) {
      message.error('선택한 학생이 이미 추가되었습니다.');
      return;
    }

    API.post('/lecture/' + selectLecture.id + '/student', token + '', {
      studentIds: AddStudent
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('학생이 추가되었습니다');
        setIsAdd(false);
        DashboardLectureStudentMutate();
      }
    })
    .catch((err: any) => {})
    
  }

  const sortBySchoolTypeAndName = (a, b) => {
    const order = ['N수', '고', '중', '초'];
  
    const indexOfA = order.indexOf(a.schoolType);
    const indexOfB = order.indexOf(b.schoolType);
  
    // schoolType에 따라 정렬
    const schoolTypeComparison = indexOfA - indexOfB;
    if (schoolTypeComparison !== 0) {
      return schoolTypeComparison;
    }
  
    // schoolType이 같으면 이름에 따라 가나다순 정렬
    return a.name.localeCompare(b.name);
  };

  // lectureAll 배열을 schoolType에 따라 정렬
  const sortedLectureData = LectureData?.slice().sort(sortBySchoolTypeAndName).reverse();
  const sortedLectureMyData = LectureMyData?.slice().sort(sortBySchoolTypeAndName).reverse();

  return (
    <>
      {/*{!myInfo?.master &&*/}
      {/*  <SelectLectureHeader>*/}
      {/*    <LectureButton onClick={() => setLectureType('today')}>금일 강의리스트</LectureButton>*/}
      {/*    <LectureButton onClick={() => setLectureType('total')}>나의 강의리스트</LectureButton>*/}
      {/*  </SelectLectureHeader>*/}
      {/*}*/}

      <HomeWrapper>
        {lectureType === 'today' ?
          <TodayLecture>
            <Title>
              금일 강의리스트
              <span>{LectureData?.length}</span>
            </Title>
            <Table>
              <tbody>
              <th>No.</th>
              <th>시간</th>
              <th>강의</th>
              <th>방식</th>
              <th>학년</th>
              <th>강사</th>
              <th className='person'>정원</th>
              {sortedLectureData?.map((it, idx) => (
                <tr
                  key={idx}
                  onClick={() => handleTableClick(it)}
                  style={{
                    backgroundColor: selectLecture?.name == it.name ? '#F78B8580' : ''
                  }}
                >
                  <td>{idx + 1}</td>
                  <td>
                    {it.time === '' || it.time === '00:00' ? '-' : (it?.time?.slice(3,5) === '00' ? it?.time?.slice(0,2) + '시간' : (it?.time?.slice(0,2) + '시간' + it?.time?.slice(3,5) + '분'))}
                  </td>
                  <td style={{textAlign: 'left'}}>{it.name}</td>
                  <td>{it.type}</td>
                  <td>{it.grade}</td>
                  <td>{it?.teacherNames?.map((it) => <p>{it}</p>)}</td>
                  <td className='person'><span>{it.totalCount == null ? '0' : it.totalCount}</span> / {it.maxCount}</td>
                </tr>
              ))}
              </tbody>
            </Table>
          </TodayLecture>
          :
          <TodayLecture>
            <Title>
              나의 강의리스트
              <span>{LectureMyData?.length}</span>
            </Title>
            <Table>
              <tbody>
              <th>No.</th>
              <th>시간</th>
              <th>강의</th>
              <th>방식</th>
              <th>학년</th>
              <th>강사</th>
              <th className='person'>정원</th>
              {sortedLectureMyData?.map((it, idx) => (
                <tr
                  key={idx}
                  onClick={() => handleTableClick(it)}
                  style={{
                    backgroundColor: selectLecture?.name == it.name ? '#F78B8580' : ''
                  }}
                >
                  <td>{idx + 1}</td>
                  <td>
                    {it.time == '' || it.time == '00:00' ? '-' : (it?.time?.slice(3,5) === '00' ? it?.time?.slice(0,2) + '시간' : (it?.time?.slice(0,2) + '시간' + it?.time?.slice(3,5) + '분'))}
                  </td>
                  <td style={{textAlign: 'left'}}>{it.name}</td>
                  <td>{it.type}</td>
                  <td>{it.grade}</td>
                  <td>{it?.teacherNames?.map((it) => <p>{it}</p>)}</td>
                  <td className='person'><span>{it.totalCount == null ? '0' : it.totalCount}</span> / {it.maxCount}</td>
                </tr>
              ))}
              </tbody>
            </Table>
          </TodayLecture>
        }

        <SelectLecture>
          {!selectLecture ? (
            <NotSelectLecture>
              <p>강의를 선택해주세요.</p>
            </NotSelectLecture>
          ) : (
            <IsSelectLecture>
              <LectureHeader>
                <div className='title'>
                  <p>{selectLecture.name}</p>
                  <span>{selectLecture.time === '' || selectLecture.time === '00:00' ? '' : (selectLecture?.time?.slice(3,5) === '00' ? selectLecture?.time?.slice(0,2) + '시간' : '-')}</span>
                </div>
                {selectLecture.type == '강의식' &&
                  <Button onClick={() => setLectureSt(true)}>
                    <Icon><BsFillPencilFill /></Icon>
                    <p>학습일지</p>
                  </Button>
                }
              </LectureHeader>
              <div className='scroll' style={{overflowY: 'auto', maxHeight: '728px'}}>
                <Table>
                  <tbody>
                  <th>No.</th>
                  <th style={{width: '40px', padding: '0px'}}>출석</th>
                  <th className='tdName'>이름</th>
                  <th>비고</th>
                  {DashboardLectureStudentsData?.map((it, idx) => (
                    it.name !== null && (
                      <tr key={idx} style={{cursor: 'auto'}}>
                        <td>{idx + 1}</td>
                        <td>
                          <Icon
                            onClick={() => handleCheckClick(it.studentId, it.attendanceStatus)}
                            style={{
                              cursor: 'pointer',
                              width: '20px',
                              height: '20px',
                              borderRadius: '50%',
                              border: it.attendanceStatus == 1 ? '1px solid #4C4C4C' : '2px solid #D2D2D2',
                              backgroundColor: it.attendanceStatus == 1 ? '#4C4C4C' : 'transparent',
                              color: it.attendanceStatus == 1 ? '#fff' : '#D2D2D2'
                            }}>
                            <FaCheck />
                          </Icon>
                        </td>
                        <td className='tdName'>
                          {it.name}
                          {selectLecture.type == '관리식' &&
                            <Button
                              className='learnNote'
                              onClick={() => {setSelectStudentNote(it); setManageSt(true); setEditNoteIndex({id:selectLecture?.id, student: it.id}); DashboardLectureStudentMutate(); LectureListMutate();}}
                              style={{
                                border: '1px solid #EFA44F',
                                backgroundColor: it?.reportStatus === 1 ? '#EFA44F' : 'transparent',
                                color: it?.reportStatus === 1 ? '#FFF' : '#EFA44F',
                              }}>
                              <Icon>{it?.reportStatus === 1  ? <FaCheck /> : <BsFillPencilFill />}</Icon>
                              <p style={{fontSize: '14px'}}>학습일지</p>
                            </Button>
                          }
                        </td>
                        {editNoteIndex === it.id ? (
                          <td>
                            <input
                              type="text"
                              value={fixMemo[it.id]}
                              onChange={(e) => setFixMemo({ ...fixMemo, [it.id]: e.target.value })}
                              onBlur={() => {
                                handleOnBlur(it.id);
                              }}
                              autoFocus
                            />
                          </td>
                        ) : (
                          <td
                            onDoubleClick={() => handleDoubleClick(it.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {it.memo}
                          </td>
                        )}
                      </tr>
                    )
                  ))}
                  </tbody>
                </Table>
              </div>
              {myInfo?.admin || myInfo?.master ? (
                isAdd
                  ? (
                    <IsAdd>
                      <div className='AddHeader'>
                        <LectureListSearch>
                          <input type='text' placeholder='학생검색' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
                          <Icon style={{color: '#DDAFAF', scale: '1.5'}}><CiSearch /></Icon>
                        </LectureListSearch>
                        <div className='btns'>
                          <button className='add' onClick={clickAddStudent}>추가</button>
                          <button className='cancel' onClick={() => setIsAdd(false)}>취소</button>
                        </div>
                      </div>
                      <div className='AddTable'>
                        <LectureViewTable>
                          <tbody>
                          <tr>
                            <th>이름</th>
                            <th>학교명</th>
                            <th>학교</th>
                            <th>학년</th>
                          </tr>
                          {searchAddStudent()?.map((it, idx) => (
                            <tr
                              className='student'
                              key={idx}
                              onClick={() => setAddStudent((prevStudents) => {
                                const updatedStudents = prevStudents.includes(it.id)
                                  ? prevStudents.filter((id) => id !== it.id)
                                  : [...prevStudents, it.id];
                                return updatedStudents;
                              })}
                              style={{backgroundColor: AddStudent.includes(it.id) ? 'rgba(247, 139, 133, 0.5)' : 'transparent'}}
                            >
                              <td>{it.name}</td>
                              <td>{it.school}</td>
                              <td>{it.schoolType}</td>
                              <td>{it.grade}</td>
                            </tr>
                          ))}
                          </tbody>
                        </LectureViewTable>
                      </div>
                    </IsAdd>)
                  : (
                    null
                  )
              ) : null }
            </IsSelectLecture>
          )}
        </SelectLecture>
      </HomeWrapper>
    </>

  )
}


const SelectLectureHeader = styled.div`
  padding: 0 300px;
  display: flex;
  margin-top: 30px;

  @media (max-width: 1700px) {
    padding: 0 220px;
  }
  @media (max-width: 1550px) {
    padding: 0 130px;
  }
  @media (max-width: 1350px) {
    padding: 0 80px;
  }
  @media (max-width: 1250px) {
    padding: 0 50px;
  }
  @media (max-width: 700px) {
    padding: 0 12px;
  }
`

const LectureButton = styled.div`
  font-size: 16px;
  font-weight: bold;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
  border: 2px #efebeb solid;
  background-color: #FAE7E7;
  cursor: pointer;
`