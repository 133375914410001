import React, { useState } from 'react'
import { LectureListWrapper, LectureGrade, LectureGradeHeader, LectureListDisplay, LectureListHeader, LectureListSearch, LectureTotal } from '../Lecture/styles';
import { Icon } from '../Navigation/styles';
import { CiSearch } from "react-icons/ci";
import { useLectureList } from 'app/gomsacademy/hooks/LectureList';

export const LectureList = ({ 
    setIsAddLecture, 
    setIsTotal,
    selectLecture,
    setSelectLecture,
    selectGrade,
    setSelectGrade,
  }) => {
  const { LectureListData: lectureAll, mutate: lectureMutate } = useLectureList(0);
  const [searchKeyword, setSearchKeyword] = useState('');
    console.log(lectureAll)
  // 강의 목록 업데이트
  lectureMutate();

  const renderLectureTypes = (schoolType) => {
    const filteredLectures = lectureAll
      ?.filter(lecture => lecture.schoolType === schoolType)
      ?.filter(lecture => lecture.name?.toLowerCase().includes(searchKeyword.toLowerCase()));

    return (
      <>
        <LectureGradeHeader onClick={() => { setIsTotal(true); setSelectGrade(schoolType); setSelectLecture(null); }}>
          <p>{schoolType !== '무학년' && schoolType !== 'N수' ? schoolType+'등' : schoolType}</p>
          <span>{filteredLectures?.length}</span>
        </LectureGradeHeader>
        {filteredLectures?.map((lecture, idx) => (
          <LectureGrade
            key={idx}
            onClick={() => { setIsTotal(false); setSelectLecture(lecture); }}
            style={{
              backgroundColor: selectLecture?.id === lecture?.id ? '#F78B8580' : 'transparent',
              boxShadow: selectLecture?.id === lecture?.id ? '0px 0px 20px 0px #CE636326 inset' : '',
              display: selectGrade === schoolType || searchKeyword !== '' ? 'flex' : 'none'
            }}
          >
            <p>{lecture.name}</p>
            <span style={{
              backgroundColor: lecture?.type?.slice(0, 2) === '강의' ? '#DAF5E580' : '#FFE7CB80',
              color: lecture?.type?.slice(0, 2) === '강의' ? '#056B2E' : '#D3490D'
            }}>{(lecture?.type)?.slice(0, 2)}</span>
          </LectureGrade>
        ))}
      </>
    );
  };

  return (
    <LectureListWrapper>
      <LectureListHeader>
        <p>강의리스트</p>
        <button onClick={() => setIsAddLecture(true)}>강의등록</button>
      </LectureListHeader>
      <LectureListSearch>
        <input type='text' placeholder='강의 검색' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
        <Icon style={{ color: '#DDAFAF', scale: '1.5' }}><CiSearch /></Icon>
      </LectureListSearch>
      <LectureTotal onClick={() => { setIsTotal(true); setSelectLecture(undefined); setSelectGrade('전체'); }}>
        <p>전체</p>
        <span>{lectureAll?.length}</span>
      </LectureTotal>
      <LectureListDisplay>
        {renderLectureTypes('무학년')}
        {renderLectureTypes('N수')}
        {renderLectureTypes('고')}
        {renderLectureTypes('중')}
        {renderLectureTypes('초')}
      </LectureListDisplay>
    </LectureListWrapper>
  );
};

export default React.memo(LectureList);
