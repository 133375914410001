import React, { useState } from 'react'
import { LectureViewHeader, LectureViewTable } from '../Lecture/styles';
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList';
import { convertBinaryToDays } from 'utils/ciutils';
import {Button, Icon} from "../Home/styles";
import {FaCheck} from "react-icons/fa6";
import {BsFillPencilFill} from "react-icons/bs";
import {useDashboardLecture} from "../../hooks/dashboardLecture";

export const StudentTotalTable = ({ setIsTotal, setSelectStudent, selectedGrade, setSelectStudentNote, setManageSt, selectLecture, setEditNoteIndex, setSelectLecture }) => {
  const { TeacherStudentData: StudentAll } = useTeacherStudent(3);
  // const { DashboardLectureStudentsData, mutate:LectureStudentsDashboardDataMutate } = useDashboardLecture(selectLecture?.id);
  const filterStudent = (grade, schoolType = '') =>
    StudentAll?.filter((it) => it.grade === grade && it.schoolType === schoolType);

  const renderStudentRows = (students) =>
    students?.map((it, idx) => (
      <tr key={idx}>
        <td>{idx + 1}</td>
        <td style={{ textDecoration: 'underline', textAlign: 'left', cursor: 'pointer' }} onClick={() => { setIsTotal(false); setSelectStudent(it.id); setSelectStudentNote(it) }}>
          {it.name}
        </td>
        <td className='school'>{it.schoolType === '' ? '-' : it.schoolType}</td>
        <td>{it.school === '' ? '-' : it.school}</td>
        <td>{it.grade}</td>
        <td>{it.phone === '' ? '-' : it.phone}</td>
        <td>{it.phone2 === '' ? '-' : it.phone2}</td>
        <td>{it.days === '0000000' ? '-' : convertBinaryToDays(String(it.days))}</td>
        <td>{it.classCount} 과정</td>
        <td>{it.createdAt}</td>
        <td>
          {
            <div style={{display:'flex', justifyContent: 'center'}}>
              <Button
                className='learnNote'
                onClick={() => {setSelectStudentNote(it); setManageSt(true); setEditNoteIndex({id:0, student: it.id}); setSelectLecture({id: 0})}}
                style={{
                  border: '1px solid #EFA44F',
                  backgroundColor: 1 === 1 ? '#EFA44F' : 'transparent',
                  color: 1 === 1 ? '#FFF' : '#EFA44F',
                }}>
                {/*<Icon>{it.reportStatus === 1  ? <FaCheck /> : <BsFillPencilFill />}</Icon>*/}
                <p style={{fontSize: '14px'}}>학습일지</p>
              </Button>
            </div>
          }
        </td>
      </tr>
    ));

  const studentGroups = {
    전체: StudentAll,
    무학년: filterStudent('무학년'),
    N수: filterStudent('N수'),
    고3: filterStudent('3', '고'),
    고2: filterStudent('2', '고'),
    고1: filterStudent('1', '고'),
    중3: filterStudent('3', '중'),
    중2: filterStudent('2', '중'),
    중1: filterStudent('1', '중'),
    초6: filterStudent('6', '초'),
    초5: filterStudent('5', '초'),
    초4: filterStudent('4', '초'),
    초3: filterStudent('3', '초'),
    초2: filterStudent('2', '초'),
    초1: filterStudent('1', '초'),
    퇴원생: StudentAll?.filter(student => student.status === 0),
  };

  const selectedStudents = studentGroups[selectedGrade] || [];

  return (
    <>
      <LectureViewHeader>
        <p>{selectedGrade}</p>
      </LectureViewHeader>
      <div className='scroll'>
        <LectureViewTable className='studentTotal'>
          <tbody>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th className='school'>학교</th>
              <th>학교명</th>
              <th>학년</th>
              <th>학생번호</th>
              <th>학부모번호</th>
              <th>등원요일</th>
              <th>수업과정</th>
              <th>등록일</th>
              <th>학습일지</th>
            </tr>
          </tbody>
          <tbody>
            {selectedStudents.length === 0 ? (
              <tr>
                <td colSpan={11} style={{ textAlign: 'center', padding: '20px 0' }}>
                  선택한 학년에는 학생이 없습니다.
                </td>
              </tr>
            ) : (
              renderStudentRows(selectedStudents)
            )}
          </tbody>
        </LectureViewTable>
      </div>
    </>
  )
}

export default React.memo(StudentTotalTable)