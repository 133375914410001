import React, { useState } from 'react'
import {
	AddModalBtns,
	AddModalDisplay,
	AddModalHeader,
	AddModalMain,
	AddModalTable,
	Area,
	Section,
	TableMain,
	TableTitle,
} from './styles/addModalStyles'
import { Blacked, Icon } from '../organism/Navigation/styles'
import { FaChevronLeft } from 'react-icons/fa'
import { Modal, Popconfirm, message } from 'antd'
import API from 'utils/api'
import { useToken } from 'app/hooks'
import { useGetExcel } from '../hooks/GetExcel'
import { useTeacherStudent } from '../hooks/TeacherStudentList'
import TextArea from 'antd/es/input/TextArea'

export const AddStudentModal = ({ setIsAddStudent }) => {
	const { data: token } = useToken()
	const { mutate: StudentListMutate } = useTeacherStudent(3)
	const today = new Date().toISOString().split('T')[0]
	const { ExcelDown } = useGetExcel()

	const [name, setName] = useState('')
	const [school, setSchool] = useState('')
	const [schoolName, setSchoolName] = useState('')
	const [grade, setGrade] = useState(null)
	const [number, setNumber] = useState('')
	const [parentNum, setParentNum] = useState('')
	const [startDate, setStartDate] = useState(today)

	const [memo, setMemo] = useState('')

	const [isExcel, setIsExcel] = useState(false)
	const [file, setFile] = useState(null)

	const [selectedDays, setSelectedDays] = useState<string[]>([])

	const toggleDay = (day: string) => {
		setSelectedDays(prevSelectedDays => {
			if (prevSelectedDays?.includes(day)) {
				return prevSelectedDays?.filter(it => it !== day)
			} else {
				return [...prevSelectedDays, day]
			}
		})
	}

	const convertDaysToBinary = () => {
		const binaryArray = ['0', '0', '0', '0', '0', '0', '0'] // Initialize with zeros
		selectedDays?.forEach(day => {
			switch (day) {
				case '월':
					binaryArray[0] = '1'
					break
				case '화':
					binaryArray[1] = '1'
					break
				case '수':
					binaryArray[2] = '1'
					break
				case '목':
					binaryArray[3] = '1'
					break
				case '금':
					binaryArray[4] = '1'
					break
				case '토':
					binaryArray[5] = '1'
					break
				case '일':
					binaryArray[6] = '1'
					break
				default:
					break
			}
		})
		return binaryArray?.join('')
	}

	const saveName = e => {
		setName(e.target.value)
	}
	const saveSchool = e => {
		setSchool(e.target.value)
		if (e.target.value == '무학년') {
			setSchool('')
			setGrade(null)
		}
	}
	const saveSchoolName = e => {
		setSchoolName(e.target.value)
	}
	const saveGrade = e => {
		setGrade(e.target.value)
		if (e.target.value == 'null') {
			setGrade(null)
		}
	}
	const saveNumber = e => {
		setNumber(e.target.value)
	}
	const saveParentNum = e => {
		setParentNum(e.target.value)
	}
	const saveStartDate = e => {
		setStartDate(e.target.value)
	}

	const handleSave = () => {
		if (name == '') {
			message.error('학생이름을 입력해주세요.')
		} else {
			API.post('/users', token + '', {
				userType: 3,
				name: name,
				phone: number,
				email: '-',
				password: '-',
				subjectIds: [0],
				school: schoolName,
				schoolType: school,
				grade: grade,
				phone2: parentNum,
				days: convertDaysToBinary(),
				memo: memo,
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('등록되었습니다')
						StudentListMutate()
						setIsAddStudent(false)
					}
				})
				.catch((err: any) => {})
		}
	}

	const getExcel = async () => {
		try {
			const url = ExcelDown
			// a 태그를 만들어서 다운로드 링크 설정 및 클릭
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', '학생정보.xlsx')
			document.body.appendChild(link)
			link.click()

			// 다운로드가 완료되면 a 태그 제거
			document.body.removeChild(link)
		} catch (error) {
			console.error('엑셀 다운로드 중 에러 발생:', error)
		}
	}

	const handleFileChange = e => {
		const selectedFile = e.target.files[0]
		if (selectedFile) {
			setFile(selectedFile)
		}
	}

	const APIFile = () => {
		if (file) {
			let data = new FormData()
			data.append('file', file)
			console.log(`file`, file, data)

			API.postImage('POST', '/users/excel', token + '', data)
				.then((res: any) => {
					if (res.result.success) {
						message.success('등록되었습니다')
						setFile(null)
					}else{
						message.error('등록에 실패했습니다 :' + res.result.message)
					}
				})
				.catch((err: any) => {
					message.error('서버에 데이터 전송 중 에러 발생')
					console.error('서버에 데이터 전송 중 에러 발생:', err)
				})
		} else {
			message.error('파일이 선택되지 않았습니다.')
		}
	}

	return (
		<AddModalMain>
			<Blacked onClick={() => setIsAddStudent(false)} />
			<AddModalDisplay>
				<AddModalHeader>
					<Icon onClick={() => setIsAddStudent(false)}>
						<FaChevronLeft />
					</Icon>
					<p>학생등록</p>
					<AddModalBtns>
						<Popconfirm
							title={
								<>
									<label className="excel" htmlFor="excelInput" style={{ cursor: 'pointer' }}>
										파일선택
									</label>
									<input type="file" id="excelInput" onChange={handleFileChange} />
								</>
							}
							open={isExcel}
							onCancel={() => setIsExcel(false)}
							onConfirm={() => {
								APIFile()
							}}
							okText="등록"
							cancelText="취소"
						/>

						<>
							<label
								className="excel"
								style={{
									fontSize: '13px',
									border: 'none',
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
								onClick={() => setIsExcel(true)}>
								엑셀등록
							</label>
							<button className="save" onClick={handleSave}>
								저장
							</button>
						</>
					</AddModalBtns>
				</AddModalHeader>
				<AddModalTable>
					<Area className="second">
						<Section>
							<TableTitle>학생명 *</TableTitle>
							<TableMain>
								<input type="text" placeholder="이름 작성" onChange={saveName} />
							</TableMain>
						</Section>
						<Section>
							<TableTitle>학교</TableTitle>
							<TableMain>
								<select onChange={saveSchool}>
									<option value="무학년">--</option>
									<option value="">N수</option>
									<option value="고">고</option>
									<option value="중">중</option>
									<option value="초">초</option>
								</select>
							</TableMain>
						</Section>
					</Area>
					<Area className="third">
						<Section>
							<TableTitle>학교명</TableTitle>
							<TableMain>
								<input type="text" placeholder="N수생인 경우 공란" onChange={saveSchoolName} />
							</TableMain>
						</Section>
						<Section>
							<TableTitle>학년</TableTitle>
							<TableMain>
								<select onChange={saveGrade}>
									<option value="null">무학년</option>
									<option value="">N수</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
								</select>
							</TableMain>
						</Section>
					</Area>
					<Area className="fifth">
						<Section>
							<TableTitle>학생번호</TableTitle>
							<TableMain>
								<input type="text" placeholder="학생번호 입력" onChange={saveNumber} />
							</TableMain>
						</Section>
						<Section>
							<TableTitle>학부모번호</TableTitle>
							<TableMain>
								<input type="text" placeholder="학부모번호 입력" onChange={saveParentNum} />
							</TableMain>
						</Section>
					</Area>
					<Area className="fourth">
						<Section>
							<TableTitle>등록일</TableTitle>
							<TableMain>
								<input type="date" value={startDate} onChange={saveStartDate} />
							</TableMain>
						</Section>
						<Section>
							<TableTitle>엑셀양식 다운로드</TableTitle>
							<TableMain className="date">
								<button
									onClick={() => {
										getExcel()
									}}>
									엑셀양식 다운로드
								</button>
							</TableMain>
						</Section>
					</Area>

					<Area className="first">
						<TableTitle>비고</TableTitle>
						<TableMain>
							<TextArea
								placeholder="비고 작성"
								onChange={e => {
									setMemo(e.target.value)
								}}
							/>
						</TableMain>
					</Area>
				</AddModalTable>
			</AddModalDisplay>
		</AddModalMain>
	)
}

export default React.memo(AddStudentModal)
