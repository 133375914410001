import React, { useState } from 'react'
import { LectureGrade, LectureListDisplay, LectureListHeader, LectureListSearch, LectureListWrapper, LectureTotal } from '../Lecture/styles';
import { Icon } from '../Navigation/styles';
import { CiSearch } from "react-icons/ci";
import { useAcademyList } from 'app/gomsacademy/hooks/AcademyList';
import { useGetMyInfo } from 'app/gomsacademy/hooks/GetMyInfo';

export const AcademyList = ({ setIsAddAcademy, selectAcademy, setSelectAcademy, setIsTotal }) => {
  const { AcademyListData } = useAcademyList();
  const { myInfo } = useGetMyInfo();
  const [keyword, setKeyword] = useState('');
  const SearchAcademy = () => {
    return AcademyListData?.filter(academy => academy?.name?.toLowerCase().includes(keyword.toLowerCase()));
  }

  return (
    <LectureListWrapper>
      <LectureListHeader>
        <p>학원리스트</p>
        <button onClick={() => setIsAddAcademy(true)}>학원등록</button>
      </LectureListHeader>
      <LectureListSearch>
        <input type='text' placeholder='학원 검색' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
        <Icon style={{color: '#DDAFAF', scale: '1.5'}}><CiSearch /></Icon>
      </LectureListSearch>
      <LectureListDisplay>
        <LectureTotal onClick={() => {setIsTotal(true); setSelectAcademy(undefined)}}>
          <p>전체</p>
          <span>{AcademyListData?.length}</span>
        </LectureTotal>
        {SearchAcademy()?.map((academy, idx) => (
          <LectureGrade 
            key={idx} 
            onClick={() => {setSelectAcademy(academy.id); setIsTotal(false)}} 
            style={{
              backgroundColor: selectAcademy == academy.id ? '#F78B8580' : 'transparent',
              boxShadow: selectAcademy == academy.id ? '0px 0px 20px 0px #CE636326 inset' : ''
            }}>
            <p>{academy.name}</p>
          </LectureGrade>
        ))}
      </LectureListDisplay>
    </LectureListWrapper>
  )
}

export default React.memo(AcademyList);