import styled from "styled-components";

export const Nav = styled.div`
  width: 100%;
  height: 60px;
  /* min-height: 60px !important; */
  background-color: #C74C45;
  color: #fff;
  box-shadow: 0px 4px 16px 0px #7E1E1740;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  min-width: 360px;
  @media (max-width: 1500px) {
    padding: 0 20px;
  }
  @media (max-width: 1023px) {
    padding: 10px 12px;
    height: 44px;
  }
`

export const NavLeftArea = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 50px;
  @media (max-width: 1500px) {
    gap: 20px;
  }
`
export const NavRightArea = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  .admin {
    display: flex;
    gap: 10px;
    align-items: center;
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: underline;
        color: #EFEFEF;
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
      .select {
        z-index: 9;
        position: absolute;
        width: 110px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        top: 30px;
        right: 0px;
        border: 1px solid #666;
        border-radius: 4px;
        p {
          color: #666;
          font-size: 16px;
          letter-spacing: -1px;
          font-weight: 400;
          padding: 10px 12px;
          border-bottom: 1px solid #666;
          &:hover {
            background-color: #F78B85;
            color: #fff;
            cursor: pointer;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  @media (max-width: 1500px) {
    gap: 10px;
    .admin {
      font-size: 13px;
    }
  }
  @media (max-width: 1023px) {
    .admin {
      display: none;
    }
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  .title {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  h1 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -1px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -1px;
  }
  .icon {
    display: none;
  }
  @media (max-width: 1500px) {
    h1 {
      font-size: 18px;
    }
    p {
      font-size: 12px;
    }
  }
  @media (max-width: 1120px) {
    .none {
      display: none;
    }
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
  @media (max-width: 1023px) {
    .icon {
      display: inline-flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
`

export const NavBtn = styled.div`
  width: 540px;
  height: 100%;
  display: flex;
  align-items: center;
  button {
    font-family: 'Pretendard-Regular';
  }
  @media (max-width: 1500px) {
    width: 440px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
`
export const Btn = styled.button`
  width: 90px;
  height: 100%;
  padding: 8px 10px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -1px;
  border: none;
  background-color: transparent;
  color: #fff;
  &:hover {
    background-color: #AF3C35;
  }
  &.selected {
    background-color: #AF3C35;
  }
  @media (max-width: 1500px) {
    font-size: 13px;
  }
`

export const NavInputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #BA7070;
  border: 1px solid #DDAFAF;
  padding: 10px 12px;
  border-radius: 2px;
  width: 220px;
  height: 38px;
  background-color: #fff;
  margin-right: 20px;
  @media (max-width: 1500px) {
    width: 180px;
    margin-right: 0px;
    padding: 8px 10px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
`
export const NavInput = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  &::placeholder {
    color: #BA7070;
  }
`
export const Icon = styled.div`
  width: 18px;
  height: 18px;
  color: #DDAFAF;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Time = styled.div`
  p {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -1px;
  }
  @media (max-width: 1500px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 1023px) {
    p {
      font-size: 14px;
    }
  }
`

// ** Mobile Navigation CSS =====
export const MobileSideMain = styled.div`
  display: none;
  min-width: 360px;
  @media (max-width: 1023px) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`
export const Blacked = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`
export const MobileSideDisplay = styled.div`
  width: 280px;
  height: 100%;
  background-color: #C74C45;
  box-shadow: 0px 4px 16px 0px #7E1E1740;
  position: absolute;
  top: 0;
  z-index: 99;
`
export const SideHeader = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 16px 0px #7E1E1740;
  p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -1px;
    color: #fff;
  }
  .logout {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -2px;
    color: #EFEFEF;
    cursor: pointer;
  }
`
export const SideSearch = styled.div`
  padding: 10px 12px;
`
export const SideInputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #BA7070;
  border: 1px solid #DDAFAF;
  padding: 10px 12px;
  border-radius: 2px;
  width: 100%;
  height: 38px;
  background-color: #fff;
`

export const SideBtns = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  button {
    width: 100%;
    padding: 8px 20px;
    border: none;
    background-color: transparent;
    text-align: left;
    color: #fff;
    cursor: pointer;
    &.selected {
      background-color: #AF3C35;
      /* box-shadow: 0px 0px 20px 0px #68130D33 inset; */
    }
  }
`

export const AcademySelect = styled.select`
  border: none;
  background-color: transparent;
  color: #DADADA;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -1px;
  text-decoration: underline;
  min-width: 80px;
  option {
    color: #000;
  }
`
export const ManageAcademy = styled.button`
  border: 1px solid #EFA44F;
  background-color: #F5AE5C;
  padding: 5px 8px;
  border-radius: 4px;
  color: #462A0B;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -1px;
`
export const DirectorSelect = styled.select`
  border: none;
  background-color: transparent;
  color: #DADADA;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -1px;
  text-decoration: underline;
  option {
    color: #000;
  }
`

export const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const ResultArea = styled.div`
  z-index: 9;
  position: absolute;
  width: 220px;
  top: 40px;
  background-color: #fff;
  color: #666666;
  font-size: 14px;
  border: 1px solid #666;
  border-radius: 4px;
  > div > p {
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #666;
    &:last-child {
      border-bottom: none;
    }
    span {
      padding: 0px 4px;
      border-radius: 2px;
      height: 18px;
      width: 29px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -1px;
    }
    &:hover {
      background-color: #F78B8580;
    }
  }
`