import React, { useEffect, useState } from 'react'
import { FixBtns, LectureView, LectureViewHeader, LectureViewTable, LectureWrapper } from '../Lecture/styles';
import AcademyList from './AcademyList';
import AcademyTotalTable from './AcademyTotalTable';
import AddAcademyModal from 'app/gomsacademy/components/AddAcademyModal';
import { useAcademyList } from 'app/gomsacademy/hooks/AcademyList';
import API from 'utils/api';
import { useToken } from 'app/hooks';
import { message } from 'antd';


export const Academy = () => {
  const { data: token } = useToken();
  const { AcademyListData, mutate:academyMutate } = useAcademyList();
  
  const [isAddAcademy, setIsAddAcademy] = useState(false);
  const [selectAcademy, setSelectAcademy] = useState('');
  const [isTotal, setIsTotal] = useState(true);
  const [isFix, setIsFix] = useState(false);
  const academy = AcademyListData.filter((it) => it.id == selectAcademy);
  const [editPassword, setEditPassword] = useState(false)
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [fixName, setFixName] = useState(academy[0]?.name || '');
  const [fixDirector, setFixDirector] = useState(academy[0]?.representativeName || '');
  const [fixTel, setFixTel] = useState(academy[0]?.tel || '');
  const [fixDesc, setFixDesc] = useState(academy[0]?.desc || '');
  const [fixAddress, setFixAddress] = useState(academy[0]?.address || '');
  const [fixCreatedAt, setFixCreatedAt] = useState(academy[0]?.createdAt || '');

  const DeleteAcademy = (branchId, academy) => {
    const shouldDelete = window.confirm(`정말로 '${academy}' 지점을 삭제하시겠습니까?`);

    if(shouldDelete) {
      API.delete('/branch/' + branchId, token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          message.success('삭제되었습니다');
          academyMutate();
          setIsTotal(true);
        }else{
         
        	if (res?.result?.message?.includes('foreign key')) {
						message.error('연결된 데이터가 있어 삭제할 수 없습니다.')
					} else {
						message.error(res?.result?.message || '삭제에 실패했습니다.')
					}
        }
      })
      .catch((err: any) => {})
    }
  }
  const SaveAcademy = (branchId) => {
    API.put('/branch/' + branchId, token + '', {
      datas: {
        name: fixName,
        representativeName: fixDirector,
        tel: fixTel,
        desc: fixDesc,
        address: fixAddress,
        createdAt: fixCreatedAt,
      }
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('수정되었습니다');
        academyMutate();
        setIsFix(false);
      }
    })
    .catch((err: any) => {})
  }

  const savePassword = (userId) => {
    API.put(`/users/` + userId, token + '', {
      password: newPassword,      
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('비밀번호가 변경되었습니다.')
        academyMutate();
        setEditPassword(false)
      }
    })
    .catch((err: any) => {})
  }
  
  const handleClickOutsideSelect = (event) => {
    if (!event.target.closest('.selectAcademy')) {
      setIsFix(false);
    }
    if (!event.target.closest('.editPassword')) {
      setEditPassword(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSelect);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSelect);
    };
  }, []);

  return (
    <LectureWrapper>
      <AcademyList 
        setIsAddAcademy={setIsAddAcademy}
        selectAcademy={selectAcademy}
        setSelectAcademy={setSelectAcademy}
        setIsTotal={setIsTotal}
      />
      <LectureView>
        {isTotal && (
          <AcademyTotalTable setSelectAcademy={setSelectAcademy} setIsTotal={setIsTotal} />
        )}
        {isTotal == false && selectAcademy && (
          <div className='selectAcademy'>
            <LectureViewHeader>
              <p>{academy[0]?.name}</p>
              <FixBtns>
                {!isFix ? (
                  <>
                    <button className='fix' onClick={() => setIsFix(true)}>수정</button>
                    <button className='delete' onClick={() => DeleteAcademy(academy[0]?.id, academy[0]?.name)}>삭제</button>
                  </>
                ) : (
                  <>
                    <button className='fix' onClick={() => SaveAcademy(academy[0]?.id)}>저장</button>
                    <button className='delete' onClick={() => setIsFix(false)}>취소</button>
                  </>
                )}
              </FixBtns>
            </LectureViewHeader>
            <LectureViewTable>
              <tbody>
                <tr>
                  <th>학원명</th>
                  <th>대표원장</th>
                  <th style={{minWidth: '60px'}}>아이디</th>
                  <th>비밀번호</th>
                  <th>전화번호</th>
                  <th>주소</th>
                  <th>등록일</th>
                  <th>비고</th>
                </tr>
                <tr>
                  <td>
                    {!isFix ? (
                      academy[0]?.name
                    ) : (
                      <input type='text' placeholder={`${academy[0]?.name}`} onChange={(e) => setFixName(e.target.value)} />
                    )}
                  </td>
                  <td>
                    {!isFix ? (
                      academy[0]?.representativeName
                    ) : (
                      <input type='text' placeholder={`${academy[0]?.representativeName}`} onChange={(e) => setFixDirector(e.target.value)} />
                    )}
                  </td>
                  <td>
                    {academy[0]?.email}
                  </td>
                  <td className='editPassword'>
                    {!editPassword ? (
                      <button 
                        style={{
                          minWidth: '95px',
                          border: '1px solid #676767', 
                          backgroundColor: '#222222', 
                          color: '#fff', 
                          borderRadius: '4px',
                          padding: '5px 5px'
                        }} 
                        onClick={() => setEditPassword(true)}
                      >
                        비밀번호 변경
                      </button>
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <input type='password' placeholder='새 비밀번호 입력' autoFocus onChange={(e) => setPassword(e.target.value)} />
                        <div style={{display: 'flex', gap: '4px', justifyContent: 'center'}}>
                          <button 
                            style={{borderRadius: '4px', backgroundColor: '#666', color: '#Fff'}} 
                            onClick={() => setEditPassword(false)}
                          >
                            취소
                          </button>
                          <button 
                            style={{borderRadius: '4px', backgroundColor: '#F78B85', color: '#Fff', border: '1px solid #F78B85'}} 
                            onClick={() => savePassword(academy[0]?.id)}
                          >
                            변경
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    {!isFix ? (
                      academy[0]?.tel
                    ) : (
                      <input type='text' placeholder={`${academy[0]?.tel}`} onChange={(e) => setFixTel(e.target.value)} />
                    )}
                  </td>
                  <td>
                    {!isFix ? (
                      academy[0]?.address
                    ) : (
                      <input type='text' placeholder={`${academy[0]?.address}`} onChange={(e) => setFixAddress(e.target.value)} />
                    )}
                  </td>
                  <td>
                    {!isFix ? (
                      academy[0]?.createdAt
                    ) : (
                      <input type='date' onChange={(e) => setFixCreatedAt(e.target.value)} />
                    )}
                  </td>
                  <td className='person'>
                    {!isFix ? (
                      academy[0]?.desc
                    ) : (
                      <input type='text' placeholder={`${academy[0]?.desc}`} onChange={(e) => setFixDesc(e.target.value)} />
                    )}
                  </td>
                </tr>
              </tbody>
            </LectureViewTable>
          </div>
        )}
      </LectureView>
      {isAddAcademy && <AddAcademyModal setIsAddAcademy={setIsAddAcademy} />}
    </LectureWrapper>
  )
}

export default React.memo(Academy);