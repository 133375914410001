import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/dashboard/attendance?lectureId=' // (추가) 출결조회

let swrData: any = getPersistData(KEY)

export function useGetAttendance(lectureId) {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY + lectureId + '&page=1&maxData=1000',
		async () => {
			const response = await API.get(KEY + lectureId + '&page=1&maxData=1000', token + '', {})
			swrData = response.result?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		AttendanceData: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
