import React, { useState } from 'react'
import { AcademyViewTable, LectureViewHeader } from '../Lecture/styles';
import { FaCheck } from "react-icons/fa6";
import { useAcademyList } from 'app/gomsacademy/hooks/AcademyList';

export const AcademyTotalTable = ({ setSelectAcademy, setIsTotal }) => {
  const { AcademyListData } = useAcademyList();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <LectureViewHeader>
        <p>전체</p>
        <div className='checkArea'>
          <div className='checkboxShape'>
            <input type='checkbox' id='view' onChange={() => setIsChecked(!isChecked)} checked={isChecked} />
            <div className={`icon ${isChecked ? 'checked' : ''}`} onClick={() => setIsChecked(!isChecked)}><FaCheck /></div>
          </div>
          <label htmlFor="view">과거학원 보기</label>
        </div>
      </LectureViewHeader>
      <div className='scroll'>
        <AcademyViewTable>
          <tbody>
            <tr>
              <th>No.</th>
              <th>학원명</th>
              <th>대표원장</th>
              <th>아이디</th>
              <th className='phone'>전화번호</th>
              <th>주소</th>
              <th>등록일</th>
              <th>비고</th>
            </tr>
          </tbody>
          <tbody>
            {AcademyListData.map((it, idx) => (
              <tr key={idx} className='trHover' onClick={() => {setSelectAcademy(it.id); setIsTotal(false)}}>
                <td>{idx + 1}</td>
                <td 
                  style={{
                    textDecoration: 'underline',
                    textAlign: 'left',
                    cursor: 'pointer'
                  }}
                >
                  {it.name}
                </td>
                <td>{it.representativeName}</td>
                <td>{it.email}</td>
                <td className='phone'>{it.tel}</td>
                <td>{it.address}</td>
                <td>{it.createdAt}</td>
                <td>{it.desc}</td>
              </tr>
            ))}
          </tbody>
        </AcademyViewTable>
      </div>
    </>
  )
}

export default React.memo(AcademyTotalTable)