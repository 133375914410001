import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/users/' // (3)사용자 조회 / userId 필요

let swrData: any = getPersistData(KEY)

export function useGetUser(userId) {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY + userId,
		async () => {
			const response = await API.get(KEY + userId, token + '', {})
			swrData = response.result?.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		UserData: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
