import React, { useState, useRef, useEffect } from 'react';
import { Icon, ManageAcademy, NavInput, NavInputArea, NavRightArea, ResultArea, SearchArea } from '../Navigation/styles';
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa6";
import CurrentTime from 'app/gomsacademy/components/CurrentTime';
import { useSearch } from 'app/gomsacademy/hooks/SearchBar';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetMyInfo } from 'app/gomsacademy/hooks/GetMyInfo';
import { useToken } from 'app/hooks';

export const NavRight = ({ setIsEdit, setMenu }) => {
  const {data:token} = useToken()
  const { myInfo } = useGetMyInfo();
  const [keyword, setKeyword] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [style, setStyle] = useState(true)
  const { SearchResult } = useSearch(keyword);
  const searchAreaRef = useRef<HTMLDivElement | null>(null);
  const navigate = useHistory()
  const location = useLocation()
  const { pathname } = location;
  const current = pathname.split("/admin/academy/")[1]

  const handleIsSearch = () => {
    setIsSearch(!isSearch);
  };

  const clickResultLecture = (it) => {
    navigate.push(`/admin/academy/lecture?id=${it}`);
    setMenu('lecture')
  }
  const clickResultStudent = (it) => {
    navigate.push(`/admin/academy/student?id=${it}`);
    setMenu('student')
  }

  const handleClickOutsideSelect = (event) => {
    if (!event.target.closest('.select')) {
      setStyle(true);
    }
    if (!event.target.closest('.resultArea')) {
      setIsSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSelect);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSelect);
    };
  }, []);
  
  return (
    <NavRightArea>
      <div className='admin'>
        <div className='info'>
          <button onClick={() => setStyle(!style)}>
            {myInfo?.name}
            <Icon><FaChevronDown /></Icon>
          </button>
          <div className='select' style={{display: style ? 'none' : 'block'}}>
            {myInfo?.master ? (
              <>
                {current == 'academy' ? null : (
                  <p onClick={() => {navigate.push('/admin/academy/academy'); setMenu('academy'); setStyle(true)}}>지점관리</p>
                )}
              </>
            ):null}
            {token ? (
              <>
                <p onClick={() => {setIsEdit(true); setStyle(true)}}>개인정보 수정</p>
                <p onClick={() => navigate.push('/admin/logout')}>로그아웃</p>
              </>
            ) : (
              <p onClick={() => navigate.push('/admin/login')}>로그인</p>
            )}
          </div>
        </div>
        {current == 'academy' ? (
          <ManageAcademy onClick={() => {navigate.push('/admin/academy/'); setMenu('')}}>학원관리</ManageAcademy>
        ) : (
          myInfo?.admin ? (
            <SearchArea ref={searchAreaRef}>
              <NavInputArea>
                <NavInput
                  type='text'
                  placeholder='학생, 강의 검색'
                  onChange={(e) => setKeyword(e.target.value)}
                  onFocus={() => setIsSearch(true)}
                />
                <Icon onClick={handleIsSearch}>
                  <CiSearch />
                </Icon>
              </NavInputArea>
              <ResultArea className='resultArea' style={{ display: isSearch ? 'block' : 'none' }}>
                {keyword !== '' && (
                  <div>
                    {SearchResult?.lectureList?.list?.map((it, idx) => (
                      <p key={idx} onClick={() => clickResultLecture(it.id)}>
                        {it.name}
                        <span 
                          style={{backgroundColor: '#DAF5E580', color: '#056B2E'}}
                        >
                          강의
                        </span>
                      </p>
                    ))}
                    {SearchResult?.studentList?.list?.map((it, idx) => (
                      <p key={idx} onClick={() => clickResultStudent(it.id)}>
                        {it.name}
                        <span 
                          style={{backgroundColor: '#FFE7CB80', color: '#D3490D'}}
                        >
                          학생
                        </span>
                      </p>
                    ))}
                  </div>
                )}
              </ResultArea>
            </SearchArea>
          ) : (
            null
          )
        )}
      </div>
      <CurrentTime />
    </NavRightArea>
  );
};

export default React.memo(NavRight);