import React, { useState, useEffect } from 'react';
import { Time } from '../organism/Navigation/styles';

const CurrentTime: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<string>('');

  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
      const day = now.getDate();
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      const dayOfWeek = now.toLocaleDateString('ko-KR', { weekday: 'short' });
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');

      const formattedTime = `${year}-${formattedMonth}-${formattedDay}(${dayOfWeek}) ${hours}:${minutes}`;
      setCurrentTime(formattedTime);
    };

    // 최초 렌더링 시 시간을 업데이트하고, 1분마다 갱신합니다.
    updateCurrentTime();
    const intervalId = setInterval(updateCurrentTime, 60000);

    // 컴포넌트 언마운트 시 clearInterval로 간격을 정리합니다.
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Time>
      <p>
        {currentTime}
      </p>
    </Time>
  );
};

export default CurrentTime;