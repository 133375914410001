import React, {useEffect, useState} from 'react'
import {Blacked, Icon} from '../Navigation/styles'
import {TiArrowSortedDown} from 'react-icons/ti'
import {FaChevronLeft} from 'react-icons/fa6'
import {
  ManageNote,
  ManageStyleModalMain,
  NoteTable,
  TableHeader,
  NoteHeader,
  TextArea,
  NoteTableWrapper,
  MobileTable, SubManageNote,
} from '../../components/styles/modalStyles'
import {useManageLearningNote} from '../../hooks/LearningNote'
// import { AddManageLearningNote } from '../Na'
import {useToken} from 'app/hooks'
import API from 'utils/api'
import {message} from 'antd'
import {useLectureList} from "../../hooks/LectureList";
import {useGetUser} from "../../hooks/GetUser";

export const Dailyreport = ({
                              selectLecture,
                              selectStudentNote,
                              setManageSt,
                              editNoteIndex,
                              handleWriteClick,
                              setIsWrite,
                            }) => {
  const [studentId, setStudentId] = useState(0);
  const [lectureId, setLectureId] = useState(0);
  const [date, setDate] = useState('');
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [queryData, setQueryData] = useState([{
    content: "",
    createdAt: "",
    homework: "",
    id: 0,
    memo: "",
    type: 0,
    lectureName: '',
    teacherNames: ''
  }]);
  const [lectureData, setLectureData] = useState({
    days: "",
    endedAt: "",
    id: 0,
    name: "",
    schoolType: "",
    startedAt: "",
    teacherNames: [],
    time: "",
    type: ""
  });


  // const {ManageLearningNoteData, mutate: LearningNoteMutate, changeDateFilter,} = useManageLearningNote(String(lectureId), String(studentId))
  const LearningNote = queryData?.filter(it => it.type === 1) || []
  const monthNote = queryData?.filter(it => it.type === 2) || []
  const [isAdd, setIsAdd] = useState(false)
  const [isAddMobile, setIsAddMobile] = useState(false)
  const [isFix, setIsFix] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const [fixContent, setFixContent] = useState('')
  const [fixHomework, setFixHomework] = useState()
  const [fixMemo, setFixMemo] = useState()
  const [fixMonthNote, setFixMonthNote] = useState(monthNote[0]?.content || '')

  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth() + 1
  const day = now.getDate()
  const sliceYear = year.toString()?.slice(-2)
  const formattedMonth = month < 10 ? `0${month}` : month
  const formattedDay = day < 10 ? `0${day}` : day
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const formattedTime = `${year}-${formattedMonth}-${formattedDay} ${hours}:${minutes}`

  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(month)
  const [dateArrangeData, setDateArrangeData] = useState('')

  const apiurl = '/dashboard/lecture/' + lectureId + '/report/'


  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    const today = new Date();  // 현재 날짜 및 시간을 가져옵니다.
    today.setFullYear(year);  // 현재 년도 설정 (연도 상태 변수를 사용)
    today.setMonth(selectedMonth - 1, 1);  // 선택된 월 설정, 날짜를 1일로 설정합니다.

    // 해당 월의 마지막 날짜를 구합니다.
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

    // 연도는 두 자리로 표현합니다.
    const sliceYear = today.getFullYear().toString()?.slice(-2);
    const formattedMonth = (today.getMonth() + 1).toString().padStart(2, '0');

    // 상태 업데이트 (상단의 코드에 이미 선언된 변수와 충돌하지 않도록 새로운 변수명 사용)
    const newFormattedMonthRange = `${sliceYear}-${formattedMonth}-01 ~ ${sliceYear}-${formattedMonth}-${lastDay}`;

    setDateArrangeData(newFormattedMonthRange);  // 날짜 필터 함수를 업데이트하는 훅 (서버 요청 등에 사용될 수 있습니다.)
  }, [selectedMonth, year]);


  useEffect(() => {
    const studentId = params.get('studentId');
    const lectureId = params.get('lectureId');
    const date = params.get('date');
    const id = params.get('id');
    const pw = params.get('pw');

    setStudentId(Number(studentId))
    setLectureId(Number(lectureId))
    setDate(String(date))
    setId(String(id))
    setPw(String(pw))

  }, []);


  useEffect(() => {
    API.get('/report?' + 'studentId=' + studentId + '&lectureId=' + lectureId + '&date=' + date + '&id=' + id + '&pw=' + pw ,  '', {
    })
      .then((res: any) => {
        if (res.result.success) {
          // message.success('Success GET')
          setQueryData(res?.result?.result?.list || []);
        } else {
          message.error(res?.result?.message || 'Fail')
        }
      })
      .catch((err: any) => {})
  }, [pw]);


  useEffect(() => {
    const today = now.toISOString()?.slice(0, 10)?.replace(/-/g, '.')

    const hasTodayNote = queryData?.some(note => {
      const noteDate = note.createdAt.split(' ')[0]
      return noteDate === today
    })
    if (hasTodayNote) {
      // setIsWrite(prevState => {
      //   return [
      //     ...prevState,
      //     {
      //       id: selectLecture?.id,
      //       student: selectStudentNote.studentId || selectStudentNote.id,
      //       value: true,
      //     },
      //   ]
      // })
    }
  }, [queryData, selectLecture, selectStudentNote, setIsWrite])

  // const handleSaveButtonClick = () => {
  //   if (monthNote.length == 0) {
  //     API.post(apiurl, token + '', {
  //       type: 2,
  //       studentIds: [selectStudentNote.studentId || selectStudentNote.id],
  //       createdAt: '',
  //       content: fixMonthNote,
  //       homework: '',
  //       memo: '',
  //     })
  //       .then((res: any) => {
  //         if (res.result.success) {
  //           handleWriteClick(editNoteIndex)
  //           message.success('저장되었습니다')
  //           // LearningNoteMutate()
  //           setManageSt(false)
  //         }
  //       })
  //       .catch((err: any) => {
  //       })
  //   } else {
  //     API.put(
  //       apiurl +
  //       monthNote[0]?.id +
  //       '?studentId=' +
  //       (selectStudentNote.studentId || selectStudentNote.id),
  //       token + '',
  //       {
  //         datas: {
  //           content: fixMonthNote,
  //           createdAt: formattedTime,
  //           homework: '',
  //           memo: '',
  //         },
  //       }
  //     )
  //       .then((res: any) => {
  //         if (res.result.success) {
  //           handleWriteClick(editNoteIndex)
  //           message.success('저장되었습니다')
  //           // LearningNoteMutate()
  //           setManageSt(false)
  //         }
  //       })
  //       .catch((err: any) => {
  //       })
  //   }
  // }

  // ** 수정 버튼 클릭 시 해당 인덱스 요소만 수정 가능하게
  // const handleFixBtnClick = idx => {
  //   setEditingIndex(idx)
  //   setIsFix(true)
  //   setFixContent(LearningNote[idx].content)
  //   setFixHomework(LearningNote[idx].homework)
  //   setFixMemo(LearningNote[idx].memo)
  // }
  // ** 수정 -> 저장 버튼으로 변경 후 API로 데이터 이동
  // const handleFixSaveClick = noteId => {
  //   API.put(
  //     apiurl + noteId + '?studentId=' + (selectStudentNote.studentId || selectStudentNote.id),
  //     token + '',
  //     {
  //       datas: {
  //         content: fixContent,
  //         createdAt: formattedTime,
  //         homework: fixHomework,
  //         memo: fixMemo,
  //       },
  //     }
  //   )
  //     .then((res: any) => {
  //       if (res.result.success) {
  //         message.success('수정되었습니다')
  //         setIsFix(false)
  //         setEditingIndex(null)
  //         // LearningNoteMutate()
  //       }
  //     })
  //     .catch((err: any) => {
  //     })
  // }
  // const handleDeleteBtnClick = noteId => {
  //   API.delete(
  //     apiurl + noteId + '?studentId=' + (selectStudentNote.studentId || selectStudentNote.id),
  //     token + '',
  //     {}
  //   )
  //     .then((res: any) => {
  //       if (res.result.success) {
  //         message.success('삭제되었습니다')
  //         // LearningNoteMutate()
  //       } else {
  //         message.error(res?.result?.message || '삭제에 실패했습니다.')
  //       }
  //     })
  //     .catch((err: any) => {
  //     })
  // }

  const saveFixContent = e => {
    setFixContent(e.target.value)
  }
  const saveFixHomework = e => {
    setFixHomework(e.target.value)
  }
  const saveFixMemo = e => {
    setFixMemo(e.target.value)
  }
  const saveFixMonthNote = e => {
    setFixMonthNote(e.target.value)
  }

  const handleMonthSelect = selectedMonth => {
    setSelectedMonth(selectedMonth)
    setIsMonthDropdownOpen(false)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // 엔터 키를 누르고, shift 키를 누르지 않은 경우
      e.preventDefault() // 기본 엔터 동작 방지
      setFixContent(prevContent => prevContent + '\n')
    }
  }

  useEffect(() => {
    const today = now
    today.setMonth(selectedMonth - 1)

    //2024-04
    // changeDateFilter(today.toISOString().slice(0, 7))
  }, [selectedMonth])

  return (
    <ManageStyleModalMain>
      <Blacked/>
      <SubManageNote>
        <NoteHeader>
          {/*<button*/}
          {/*  className="message"*/}
          {/*  onClick={() => {*/}
          {/*    if (window.confirm('문자전송을 하시겠습니까?')) {*/}

          {/*      console.log('selectStudentNote', selectStudentNote, selectLecture)*/}

          {/*      API.get('/report?studentId=676&lectureId=134&date=2024-5&id=b824ee&pw=87101cd6-7' , token + '', {*/}
          {/*        // type: 'report',*/}
          {/*        // studentId: selectStudentNote.studentId || selectStudentNote.id,*/}
          {/*        // lectureId: selectLecture?.id,*/}
          {/*        // date: '2024-' + selectedMonth,*/}
          {/*      })*/}
          {/*        .then((res: any) => {*/}
          {/*          if (res.result.success) {*/}
          {/*            console.log('res', res)*/}
          {/*            message.success('문자전송이 완료되었습니다')*/}
          {/*          } else {*/}
          {/*            message.error(res?.result?.message || '문자전송에 실패했습니다.')*/}
          {/*          }*/}
          {/*        })*/}
          {/*        .catch((err: any) => {})*/}

          {/*      // API.post('/dashboard/message', token + '', {*/}
          {/*      // 	type: 'report',*/}
          {/*      // 	studentId: selectStudentNote.studentId || selectStudentNote.id,*/}
          {/*      // 	lectureId: selectLecture?.id,*/}
          {/*      // 	date: '2024-' + selectedMonth,*/}
          {/*      // })*/}
          {/*      // 	.then((res: any) => {*/}
          {/*      // 		if (res.result.success) {*/}
          {/*      // 			message.success('문자전송이 완료되었습니다')*/}
          {/*      // 		} else {*/}
          {/*      // 			message.error(res?.result?.message || '문자전송에 실패했습니다.')*/}
          {/*      // 		}*/}
          {/*      // 	})*/}
          {/*      // 	.catch((err: any) => {})*/}
          {/*    }*/}
          {/*  }}>*/}
          {/*  문자전송*/}
          {/*</button>*/}
          {/*<Icon*/}
          {/*  onClick={() => setManageSt(false)}*/}
          {/*  style={{*/}
          {/*    width: '24px',*/}
          {/*    height: '24px',*/}
          {/*    color: '#666666',*/}
          {/*  }}>*/}
          {/*  <FaChevronLeft/>*/}
          {/*</Icon>*/}
          <div className="title" onClick={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)} style={{width: '95%', paddingLeft: '10%'}}>
              {/*<p>{UserData?.name}</p>*/}
              <span>{selectedMonth}월</span>
              <p>학습일지</p>

            {/*{isMonthDropdownOpen && (*/}
            {/*  <div className="selectMonth">*/}
            {/*    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => (*/}
            {/*      <p*/}
            {/*        key={m}*/}
            {/*        onClick={() => handleMonthSelect(m)}*/}
            {/*        style={{*/}
            {/*          backgroundColor: selectedMonth == m ? '#F78B85' : '',*/}
            {/*          color: selectedMonth == m ? '#FFF' : '',*/}
            {/*        }}>*/}
            {/*        {m}월*/}
            {/*      </p>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*)}*/}
            <p>
              {/*<TiArrowSortedDown/>*/}
            </p>
          </div>
          <div className="rightBtns">
            <span onClick={() => window.print()}>인쇄</span>
            {/*<button className="mobileMessage">전송</button>*/}
            {/*<button onClick={handleSaveButtonClick}>저장</button>*/}
          </div>
        </NoteHeader>
        <div className="modalMain">
          <div className="tableWrapper">
            <TableHeader>
              <p>{lectureData?.name}</p>
              <p>
                {dateArrangeData}
              </p>
            </TableHeader>
            <NoteTableWrapper>
              <NoteTable>
                <tr>
                  <th style={{minWidth: '80px'}}>회차</th>
                  <th style={{minWidth: '100px'}}>일자</th>
                  <th style={{width: '8%'}}>강의</th>
                  <th style={{width: '8%'}}>선생님</th>
                  <th style={{width: '50%'}}>학습내용</th>
                  <th style={{width: '12%'}}>과제</th>
                  <th style={{width: '12%'}}>비고</th>
                </tr>
                {LearningNote?.map((it, idx) => (
                  <tr key={idx} className="learningNote">
                    <td>{idx + 1}</td>
                    <td className="date">{it.createdAt}</td>
                    <td className="date">{it.lectureName}</td>
                    <td className="date">{it.teacherNames}</td>
                    <td className="content">
                      {editingIndex !== idx ? (
                        it.content
                      ) : (
                        <div>
													<textarea
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                            className="contentInput"
                            autoFocus
                            value={fixContent}
                            placeholder="학습내용 입력(필수) &#13;&#10;줄바꿈은 shift + Enter"
                            onChange={saveFixContent}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      {editingIndex !== idx ? (
                        it.homework
                      ) : (
                        <textarea
                          placeholder="과제 수정"
                          value={fixHomework}
                          onChange={saveFixHomework}
                        />
                      )}
                    </td>
                    <td>
                      {editingIndex !== idx ? (
                        it.memo
                      ) : (
                        <input
                          type="text"
                          placeholder="비고 수정"
                          value={fixMemo}
                          onChange={saveFixMemo}
                        />
                      )}
                    </td>
                    {/*<td>*/}
                    {/*  {!isFix ? (*/}
                    {/*    <div className="btns2">*/}
                    {/*      <button className="fixBtn" onClick={() => handleFixBtnClick(idx)}>*/}
                    {/*        수정*/}
                    {/*      </button>*/}
                    {/*      <button className="deleteBtn" onClick={() => handleDeleteBtnClick(it.id)}>*/}
                    {/*        삭제*/}
                    {/*      </button>*/}
                    {/*    </div>*/}
                    {/*  ) : (*/}
                    {/*    <div className="btns2">*/}
                    {/*      <button className="fixBtn" onClick={() => handleFixSaveClick(it.id)}>*/}
                    {/*        저장*/}
                    {/*      </button>*/}
                    {/*      <button*/}
                    {/*        className="deleteBtn"*/}
                    {/*        onClick={() => {*/}
                    {/*          setEditingIndex(null)*/}
                    {/*          setIsFix(false)*/}
                    {/*        }}>*/}
                    {/*        취소*/}
                    {/*      </button>*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*</td>*/}
                  </tr>
                ))}
              </NoteTable>
              {LearningNote?.map((it, idx) => (
                <MobileTable key={idx}>
                  <div className="tableHeader">
                    <p>{idx + 1}회차</p>
                    <p>{it.createdAt}</p>
                  </div>
                  <div className="tableMain">
                    <div className="content">
                      <h1>
                        학습
                        <br/>
                        내용
                      </h1>
                      <p>{it.content}</p>
                    </div>
                    <div className="assignment">
                      <h1>과제</h1>
                      <p>
                        {/*{it.homework == '' ? (*/}
                        {/*  <textarea className="assignmentMobile" placeholder="내용작성"/>*/}
                        {/*) : (*/}
                        {/*  it.homework*/}
                        {/*)}*/}
                        { it.homework || '-'}
                      </p>
                    </div>
                  </div>
                </MobileTable>
              ))}
            </NoteTableWrapper>
            {/* 학습일지 추가버튼 클릭 */}
            {/*<AddManageLearningNote*/}
            {/*  isAdd={isAdd}*/}
            {/*  setIsAdd={setIsAdd}*/}
            {/*  isAddMobile={isAddMobile}*/}
            {/*  setIsAddMobile={setIsAddMobile}*/}
            {/*  selectLecture={selectLecture}*/}
            {/*  selectStudentNote={selectStudentNote}*/}
            {/*  formattedTime={formattedTime}*/}
            {/*/>*/}
          </div>
          <div className="modalBottom">
            <TableHeader>
              <p style={{fontSize: '16px'}}>월간 학습현황</p>
              <p>{monthNote?.length == 0 ? '' : `작성일 : ${monthNote[0]?.createdAt}`}</p>
            </TableHeader>
            <TextArea placeholder="내용작성" disabled value={monthNote[0]?.content} />
          </div>
          {/*<div className="teacherArea">*/}
          {/*  <p>담당 강사 : {lectureData?.teacherNames?.join(', ') || '공석'}</p>*/}
          {/*</div>*/}
        </div>
      </SubManageNote>
    </ManageStyleModalMain>
  )
}

export default React.memo(Dailyreport)
