/* eslint-disable no-useless-escape */
export default class CIUtils {
	static validateIdeaPassword = value => {
		const regex = /^[0-9a-zA-Z]{6,20}$/
		return regex.test(value)
	}

	static validateEnglish = value => {
		const regex = /^[0-9a-zA-Z]{1,20}$/
		return regex.test(value)
	}

	static validateEmail = email => {
		const regex =
			/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
		return regex.test(email)
	}

	static validateName = name => {
		const regex = /^[0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]{1,20}$/
		return regex.test(name)
	}

	static validateNumber = number => {
		const regex = /^[0-9]{9,12}$/
		return regex.test(number)
	}

	static validatePassword = password => {
		const regex = /[0-9a-zA-Z]{1,}[^0-9a-zA-Z]{1,}/
		return regex.test(password)
	}

	static removeWhitespace = text => {
		const regex = /\s/g
		return text.replace(regex, '')
	}
}

export const convertBinaryToDays = (binaryString) => {
	const daysArray = ['월', '화', '수', '목', '금', '토', '일'];

	if(typeof(binaryString) == 'string'){
		binaryString = binaryString?.split('')
	}
	try{
		return binaryString?.map((bit, index) => (bit === '1' ? daysArray[index] : ''))
			?.filter((day) => day !== '')
			.join(', ');
	}catch(e){
		console.error(e, "BINARY:", binaryString);
		return '';
	}
};

export const convertDaysToBinary = (selectedDay) => {
	const binaryArray = ['0', '0', '0', '0', '0', '0', '0'];
	switch (selectedDay) {
		case '월':
			binaryArray[0] = '1';
			break;
		case '화':
			binaryArray[1] = '1';
			break;
		case '수':
			binaryArray[2] = '1';
			break;
		case '목':
			binaryArray[3] = '1';
			break;
		case '금':
			binaryArray[4] = '1';
			break;
		case '토':
			binaryArray[5] = '1';
			break;
		case '일':
			binaryArray[6] = '1';
			break;
		default:
			break;
	}
	return binaryArray.join('');
};