import React, { useState } from 'react'
import { AddTable, IsAddBtn, MobileTable, NoteTable, TableHeader } from './styles/modalStyles'
import { message } from 'antd'
import { FaPlus } from 'react-icons/fa6'
import API from 'utils/api'
import { useToken } from 'app/hooks'
import { useLearningNote } from '../hooks/LearningNote'

export const AddLectureLearningNote = ({
	isAdd,
	setIsAdd,
	isAddMobile,
	setIsAddMobile,
	selectLecture,
	formattedTime,
																				 todayMonthNoteDate,
																				 monthNoteDate
}) => {
	const { data: token } = useToken()
	const { mutate: LearningNoteMutate } = useLearningNote(selectLecture?.id)
	const [content, setContent] = useState('')
	const [homework, setHomework] = useState('')
	const [memo, setMemo] = useState('')

	const saveContent = e => {
		setContent(e.target.value)
	}
	const saveHomework = e => {
		setHomework(e.target.value)
	}
	const saveMemo = e => {
		setMemo(e.target.value)
	}

	const handleAddButtonClick = () => {
		if (content === '') {
			message.error('학습내용은 필수입력 입니다.')
			return
		}
		API.post('/dashboard/lecture/' + selectLecture?.id + '/report', token + '', {
			type: 1,
			createdAt: todayMonthNoteDate === monthNoteDate ? '' : monthNoteDate,
			content,
			homework,
			memo,
		})
			.then((res: any) => {
				if (res.result.success) {
					message.success('추가되었습니다')
					setContent('')
					setHomework('')
					setMemo('')
					setIsAdd(false)
					LearningNoteMutate()
				}
			})
			.catch((err: any) => {})
	}

	const mobileAddButtonClick = () => {
		if (content.trim() === '') {
			message.error('학습내용은 필수입력 입니다.')
			return
		}

		API.post('/dashboard/lecture/' + selectLecture?.id + '/report', token + '', {
			type: 1,
			createdAt: todayMonthNoteDate === monthNoteDate ? '' : monthNoteDate,
			content,
			homework,
			memo,
		})
			.then((res: any) => {
				if (res.result.success) {
					message.success('추가되었습니다')
					setContent('')
					setHomework('')
					setMemo('')
					setIsAddMobile(false)
				}
			})
			.catch((err: any) => {})
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter' && !e.shiftKey) {
			// 엔터 키를 누르고, shift 키를 누르지 않은 경우
			e.preventDefault() // 기본 엔터 동작 방지
			setContent(prevContent => prevContent + '\n')
		}
	}

	return (
		<>
			{isAdd ? (
				<>
					<AddTable>
						<TableHeader>
							<p>내용추가</p>
						</TableHeader>
						<NoteTable>
							<tr>
								<th>학습내용</th>
								<th>과제</th>
								<th>비고</th>
							</tr>
							<tr>
								<td>
									<textarea
										style={{width: '100%'}}
										className="contentInput"
										autoFocus
										placeholder="학습내용 입력(필수) &#13;&#10;줄바꿈은 shift + Enter"
										onChange={saveContent}
										onKeyDown={handleKeyDown}
									/>
								</td>
								<td className="assignment">
									<textarea
										className="assignmentInput"
										onChange={saveHomework}
										onKeyDown={e => {
											if (e.key === 'Enter' && !e.shiftKey) {
												// 엔터 키를 누르고, shift 키를 누르지 않은 경우
												e.preventDefault() // 기본 엔터 동작 방지
												setHomework(prevContent => prevContent + '\n')
											}
										}}
									/>
								</td>
								<td style={{position: 'relative', top: 0}}>
									<input style={{position: 'relative', top: -76}} type="text" onChange={saveMemo} />
								</td>
								<td style={{position: 'relative', top: 0}}>
									<button onClick={handleAddButtonClick} style={{position: 'relative', top: -76}}>추가</button>
								</td>
								<td style={{position: 'relative', top: 0}}>
									<button onClick={() => setIsAdd(false)} style={{position: 'relative', top: -76}}>취소</button>
								</td>
							</tr>
						</NoteTable>
					</AddTable>
				</>
			) : (
				<IsAddBtn className="notMobile" onClick={() => setIsAdd(true)}>
					<FaPlus />
					<p>추가</p>
				</IsAddBtn>
			)}
			{isAddMobile ? (
				<MobileTable>
					<div className="tableHeader">
						<p></p>
						<p></p>
					</div>
					<div className="tableMain">
						<div className="content">
							<h1>
								학습
								<br />
								내용
							</h1>
							<p>
								<input
									className="contentMobile"
									type="text"
									placeholder="학습내용 입력"
									onChange={saveContent}
								/>
							</p>
						</div>
						<div className="assignment">
							<h1>과제</h1>
							<p>
								<textarea
									className="assignmentMobile"
									onKeyDown={e => {
										if (e.key === 'Enter' && !e.shiftKey) {
											// 엔터 키를 누르고, shift 키를 누르지 않은 경우
											e.preventDefault() // 기본 엔터 동작 방지
											setHomework(prevContent => prevContent + '\n')
										}
									}}
									placeholder="내용작성"
									onChange={saveHomework}
								/>
							</p>
						</div>
					</div>
					<div className="addbtn">
						<button onClick={mobileAddButtonClick}>추가</button>
						<button onClick={() => setIsAddMobile(false)}>취소</button>
					</div>
				</MobileTable>
			) : (
				<IsAddBtn className="mobile" onClick={() => setIsAddMobile(true)}>
					<FaPlus />
					<p>추가</p>
				</IsAddBtn>
			)}
		</>
	)
}
