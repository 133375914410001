import React, {useEffect, useState} from 'react'
import { CiSearch } from 'react-icons/ci'
import { TeacherStudentSide } from '../../hooks/TeacherStudentSide'
import {
	LectureGrade,
	LectureListDisplay,
	LectureListHeader,
	LectureListSearch,
	LectureListWrapper,
	LectureTotal,
} from '../Lecture/styles'
import { Icon } from '../Navigation/styles'

export const TeacherList = ({
	setIsAddTeacher,
	setIsTotal,
	selectTeacher,
	setSelectTeacher,
	selectEmployee,
	setSelectEmployee,
}) => {
	const { TeacherStudentData: DirectorList } = TeacherStudentSide(1)
	const { TeacherStudentData: TeacherList } = TeacherStudentSide(2)
	const { TeacherStudentData: DepartmentManager } = TeacherStudentSide(4)
	const TotalEmployee =
		(DirectorList?.length == undefined ? 0 : DirectorList?.length) +
		(TeacherList?.length == undefined ? 0 : TeacherList?.length) +
		(DepartmentManager?.length == undefined ? 0 : DepartmentManager?.length)
	const [searchKeyword, setSearchKeyword] = useState('')
	const searchDirector = () => {
		return DirectorList?.filter(teacher =>
			teacher.name.toLowerCase().includes(searchKeyword.toLowerCase())
		)
	}
	const searchDepartmentManager = () => {
		return DepartmentManager?.filter(teacher =>
			teacher.name.toLowerCase().includes(searchKeyword.toLowerCase())
		)
	}
	const searchTeachers = () => {
		return TeacherList?.filter(teacher =>
			teacher.name.toLowerCase().includes(searchKeyword.toLowerCase())
		)
	}

	useEffect(() => {
		console.log('DirectorList', DirectorList)
	}, [DirectorList]);

	return (
		<LectureListWrapper>
			<LectureListHeader>
				<p>직원리스트</p>
				<button onClick={() => setIsAddTeacher(true)}>직원등록</button>
			</LectureListHeader>
			<LectureListSearch>
				<input
					type="text"
					placeholder="직원 검색"
					value={searchKeyword}
					onChange={e => setSearchKeyword(e.target.value)}
				/>
				<Icon style={{ color: '#DDAFAF', scale: '1.5' }}>
					<CiSearch />
				</Icon>
			</LectureListSearch>
			<LectureListDisplay>
				<LectureTotal
					onClick={() => {
						setIsTotal(true)
						setSelectTeacher(undefined)
						setSelectEmployee('전체')
					}}>
					<p>전체</p>
					<span>{TotalEmployee}</span>
				</LectureTotal>
				<LectureTotal
					onClick={() => {
						setSelectEmployee('원장')
						setIsTotal(true)
					}}>
					<p>원장</p>
					<span>{DirectorList?.length == undefined ? 0 : DirectorList?.length}</span>
				</LectureTotal>
				{searchDirector()?.map((teacher, idx) => (
					<LectureGrade
						key={idx}
						onClick={() => {
							setIsTotal(false)
							setSelectTeacher(teacher.id)
						}}
						style={{
							backgroundColor: selectTeacher == teacher.id ? '#F78B8580' : 'transparent',
							boxShadow: selectTeacher == teacher.id ? '0px 0px 20px 0px #CE636326 inset' : '',
							display: selectEmployee === '원장' || searchKeyword !== '' ? 'flex' : 'none',
						}}>
						<p>{teacher.name}</p>
						<span style={{ backgroundColor: '#E8D7FF80', color: '#441099' }}>{selectEmployee}</span>
					</LectureGrade>
				))}
				<LectureTotal
					onClick={() => {
						setSelectEmployee('실장')
						setIsTotal(true)
					}}>
					<p>실장</p>
					<span>{DepartmentManager?.length == undefined ? 0 : DepartmentManager?.length}</span>
				</LectureTotal>
				{searchDepartmentManager()?.map((teacher, idx) => (
					<LectureGrade
						key={idx}
						onClick={() => {
							setIsTotal(false)
							setSelectTeacher(teacher.id)
						}}
						style={{
							backgroundColor: selectTeacher == teacher.id ? '#F78B8580' : 'transparent',
							boxShadow: selectTeacher == teacher.id ? '0px 0px 20px 0px #CE636326 inset' : '',
							display: selectEmployee === '실장' || searchKeyword !== '' ? 'flex' : 'none',
						}}>
						<p>{teacher.name}</p>
						<span style={{ backgroundColor: '#E8D7FF80', color: '#441099' }}>{selectEmployee}</span>
					</LectureGrade>
				))}
				<LectureTotal
					onClick={() => {
						setSelectEmployee('강사')
						setIsTotal(true)
					}}>
					<p>강사</p>
					<span>{TeacherList?.length == undefined ? 0 : TeacherList?.length}</span>
				</LectureTotal>
				{searchTeachers()?.map((teacher, idx) => (
					<LectureGrade
						key={idx}
						onClick={() => {
							setIsTotal(false)
							setSelectTeacher(teacher.id)
						}}
						style={{
							backgroundColor: selectTeacher == teacher.id ? '#F78B8580' : 'transparent',
							boxShadow: selectTeacher == teacher.id ? '0px 0px 20px 0px #CE636326 inset' : '',
							display: selectEmployee === '강사' || searchKeyword !== '' ? 'flex' : 'none',
						}}>
						<p>{teacher.name}</p>
						<span style={{ backgroundColor: '#E8D7FF80', color: '#441099' }}>{selectEmployee}</span>
					</LectureGrade>
				))}
			</LectureListDisplay>
		</LectureListWrapper>
	)
}

export default React.memo(TeacherList)
