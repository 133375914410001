import React, {useEffect, useState} from 'react'
import { AddTable, IsAddBtn, MobileTable, NoteTable, TableHeader } from './styles/modalStyles'
import { message } from 'antd'
import { FaPlus } from 'react-icons/fa6'
import API from 'utils/api'
import { useToken } from 'app/hooks'
import { useManageLearningNote } from '../hooks/LearningNote'

export const AddManageLearningNote = ({
	isAdd,
	setIsAdd,
	isAddMobile,
	setIsAddMobile,
	selectLecture,
	selectStudentNote,
	formattedTime,
																				todayMonthNoteDate,
																				monthNoteDate
}) => {
	const { data: token } = useToken()
	const { mutate: LearningNoteMutate } = useManageLearningNote(
		selectLecture?.id,
		selectStudentNote?.studentId || selectStudentNote?.id
	)
	const [content, setContent] = useState('')
	const [homework, setHomework] = useState('')
	const [memo, setMemo] = useState('')

	const saveContent = e => {
		setContent(e.target.value)
	}
	const saveHomework = e => {
		setHomework(e.target.value)
	}
	const saveMemo = e => {
		setMemo(e.target.value)
	}

	const handleAddButtonClick = () => {
		if (content === '') {
			message.error('학습내용은 필수입력 입니다.')
			return
		}
		API.post('/dashboard/lecture/' + selectLecture?.id + '/report', token + '', {
			type: 1,
			studentIds: [selectStudentNote.studentId || selectStudentNote.id],
			createdAt: todayMonthNoteDate === monthNoteDate ? '' : monthNoteDate,
			content,
			homework,
			memo,
		})
			.then((res: any) => {
				if (res.result.success) {
					message.success('추가되었습니다')
					setContent('')
					setHomework('')
					setMemo('')
					setIsAdd(false)
					LearningNoteMutate()
				}
			})
			.catch((err: any) => {})
	}

	const mobileAddButtonClick = () => {
		if (content.trim() === '') {
			message.error('학습내용은 필수입력 입니다.')
			return
		}
		API.post('/dashboard/lecture/' + selectLecture?.id + '/report', token + '', {
			type: 1,
			studentIds: [selectStudentNote.studentId || selectStudentNote.id],
			createdAt: todayMonthNoteDate === monthNoteDate ? '' : monthNoteDate,
			content,
			homework,
			memo,
		})
			.then((res: any) => {
				if (res.result.success) {
					message.success('추가되었습니다')
					setContent('')
					setHomework('')
					setMemo('')
					setIsAddMobile(false)
					LearningNoteMutate()
				}
			})
			.catch((err: any) => {})
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();
			setContent(prevContent => prevContent + '\n');
		}
	};

	useEffect(() => {
		console.log('content', content)
	}, [content]);

	return (
		<>
			{isAdd ? (
				<>
					<AddTable>
						<TableHeader>
							<p>내용추가</p>
						</TableHeader>
						<NoteTable>
							<tr>
								<th>학습내용</th>
								<th>과제</th>
								<th>비고</th>
							</tr>
							<tr>
								<td className="content">
									<textarea
										className="contentInput"
										autoFocus
										placeholder="학습내용 입력(필수) &#13;&#10;줄바꿈은 shift + Enter"
										onChange={saveContent}
										onKeyDown={handleKeyDown}
									/>
								</td>
								<td
									className="assignment"
									style={{
										verticalAlign: 'bottom',
									}}>
									<textarea
										className="assignmentInput"
										onKeyDown={e => {
											if (e.key === 'Enter' && !e.shiftKey) {
												// 엔터 키를 누르고, shift 키를 누르지 않은 경우
												e.preventDefault() // 기본 엔터 동작 방지
												setHomework(prevContent => prevContent + '\n')
											}
										}}
										onChange={saveHomework}
									/>
								</td>
								<td className="note">
									<input className="noteInput" type="text" onChange={saveMemo} />
								</td>
								<td>
									<button onClick={handleAddButtonClick}>추가</button>
								</td>
								<td>
									<button onClick={() => setIsAdd(false)}>취소</button>
								</td>
							</tr>
						</NoteTable>
					</AddTable>
				</>
			) : (
				<IsAddBtn className="notMobile" onClick={() => setIsAdd(true)}>
					<FaPlus />
					<p>추가</p>
				</IsAddBtn>
			)}
			{isAddMobile ? (
				<MobileTable>
					<div className="tableHeader">
						<p></p>
						<p></p>
					</div>
					<div className="tableMain">
						<div className="content mobileContent">
							<h1>
								학습
								<br />
								내용
							</h1>
							<p>
								<textarea
									style={{
										whiteSpace: 'pre-wrap',
										overflowWrap: 'break-word',
									}}
									className="contentMobile"
									placeholder="학습내용 입력"
									onChange={saveContent}
									onKeyDown={handleKeyDown}
									value={content}
								/>
							</p>
						</div>
						<div className="assignment">
							<h1>과제</h1>
							<p>
								<textarea
									className="assignmentMobile"
									onKeyDown={e => {
										if (e.key === 'Enter' && !e.shiftKey) {
											// 엔터 키를 누르고, shift 키를 누르지 않은 경우
											e.preventDefault() // 기본 엔터 동작 방지
											setHomework(prevContent => prevContent + '\n')
										}
									}}
									placeholder="내용작성"
									onChange={saveHomework}
									value={homework}
								/>
							</p>
						</div>
					</div>
					<div className="addbtn">
						<button onClick={mobileAddButtonClick}>추가</button>
						<button onClick={() => setIsAddMobile(false)}>취소</button>
					</div>
				</MobileTable>
			) : (
				<IsAddBtn className="mobile" onClick={() => setIsAddMobile(true)}>
					<FaPlus />
					<p>추가</p>
				</IsAddBtn>
			)}
		</>
	)
}
