import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Icon } from '../organism/Navigation/styles'
import { FaChevronLeft } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { AddModalBtns, AddModalDisplay, AddModalHeader, AddModalMain, AddModalTable, Area, Section, TableMain, TableTitle } from './styles/addModalStyles'
import { useGetSubject } from '../hooks/GetSubjectList';
import { IsSelectLecture, LectureHeader, Table } from '../organism/Home/styles';
import { LectureListSearch } from '../organism/Lecture/styles';
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { message } from 'antd';

export const SubjectDisplay = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  padding: 0px 50px;
`
export const SubjectListWrapper = styled.div`
  width: 50%;
`
export const SubjectListHeader = styled.div`
  color: #222222;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    border: 1px solid #DDAFAF;
    background-color: #FFF9F9;
    padding: 6px 12px;
    &::placeholder {
      color: #BA7070;
      font-size: 14px;
      font-weight: 400;
    }
  }
`
export const SubjectListView = styled.div`
  height: 230px;
  overflow-y: auto;
`
export const SubjectEditWrapper = styled.div`
  width: 50%;
`
export const SelectSubjectArea = styled.div`
  display: flex;
  flex-direction: column;
  span {
    width: 100%;
    border-bottom: 1px solid #DDAFAF;
    color: #666;
    padding: 10px;
    transition: all.3s;
    cursor: pointer;
    &:hover {
      background-color: #f78b8530 !important;
    }
  }
`
export const FixSubject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 12px;
  input {
    border: 1px solid #DDAFAF;
    background-color: #FFF9F9;
    padding: 6px 12px;
    border-radius: 4px;
    &::placeholder {
      color: #BA7070;
      font-size: 14px;
      font-weight: 400;
    }
  }
  button {
    padding: 8px 12px;
    border: 1px solid #666;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Pretendard-Regular';
  }
  .fix {
    background-color: #F78B85;
    box-shadow: '0px 0px 20px 0px #CE636326 inset';
    color: #fff;
    border: 1px solid #F78B8580;
  }
  .delete {
    border: 1px solid #DDAFAF;
    background-color: #FFF9F9;
    color: #BA7070;
  }
`

interface selectSubjectProps {
  id: number,
  name: string,
  createdAt: string,
}

export const Subject = ({ setIsSubject }) => {
  const { data: token } = useToken();
  const { SubjectList, mutate: subjectMutate } = useGetSubject();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectSubject, setSelectSubject] = useState<selectSubjectProps | undefined>();
  const [subjectName, setSubjectName] = useState('');
  const [newSubjectName, setNewSubjectName] = useState('');
  const [isFix, setIsFix] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const searchSubject = () => {
    return SubjectList.filter(subject => subject.name.toLowerCase().includes(searchKeyword.toLowerCase()))
  }
  const handleSelect = (subject) => {
    if (selectSubject == subject) {
      setSelectSubject(undefined);
    } else {
      setSelectSubject(subject);
    }
  }

  const addSubject = () => {
    API.post('/system/subject/', token + '', {
      name: newSubjectName
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('과목이 등록되었습니다');
        setIsAdd(false);
        setNewSubjectName('');
        subjectMutate();
      }
    })
    .catch((err: any) => {})
  }
  const fixSubject = () => {
    API.put('/system/subject/' + selectSubject?.id, token + '', {
      name: subjectName
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('과목명이 수정되었습니다');
        setIsFix(false);
        setSelectSubject(undefined);
        subjectMutate();
      }
    })
    .catch((err: any) => {})
  }
  const deleteSubject = () => {
    const shouldDelete = window.confirm(`정말로 '${selectSubject?.name}' 과목을 삭제하시겠습니까?`);
    if(shouldDelete) {
      API.delete('/system/subject/' + selectSubject?.id, token + '', {})
      .then((res: any) => {
        if (res.result.success) {
          message.success('과목명이 수정되었습니다');
          setIsFix(false);
          setSelectSubject(undefined);
          subjectMutate();
        }else{
          message.error(res?.result?.message || '삭제에 실패했습니다.')
        }
      })
      .catch((err: any) => {})
    }
  }

  // ** 수정 누르고 다른 곳 클릭하면 닫힘
  const handleClickOutsideSelect = (event) => {
    if (!event.target.closest('.fixArea')) {
      setIsFix(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSelect);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSelect);
    };
  }, []);

  return (
    <AddModalDisplay>
      <AddModalHeader style={{marginBottom: '30px'}}>
        <Icon onClick={() => setIsSubject(false)}><FaChevronLeft /></Icon>
        <p>과목관리</p>
        <AddModalBtns>
          <p 
          style={{fontSize: '16px', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'normal', marginRight: '10px'}} 
          onClick={() => setIsSubject(false)}
          >
            강사등록
          </p>
          <button 
          onClick={() => {setIsAdd(true); setSelectSubject(undefined)}}
          style={{
            border: '1px solid #F78B85',
            backgroundColor: '#F78B85',
            borderRadius: '4px',
            padding: '4px 10px',
            color: '#FFF'
          }}
          >
            과목등록
          </button>
        </AddModalBtns>
      </AddModalHeader>
      <SubjectDisplay>
        <SubjectListWrapper>
          <SubjectListHeader>
            <p>과목리스트</p>
            <input type='text' placeholder='강의 검색' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
          </SubjectListHeader>
          <SubjectListView>
            <Table>
              <tbody>
                <th>No.</th>
                <th>과목명</th>
                {searchSubject()?.map((it, idx) => (
                  <tr 
                  key={idx} 
                  onClick={() => handleSelect(it)}
                  style={{
                    backgroundColor: it.id == selectSubject?.id ? '#F78B8580' : 'transparent',
                    boxShadow: it.id == selectSubject?.id ? '0px 0px 20px 0px #CE636326 inset' : '',
                  }}
                  >
                    <td>{idx + 1}</td>
                    <td>{it.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SubjectListView>
        </SubjectListWrapper>
        <SubjectEditWrapper>
          {selectSubject == undefined ? (
            isAdd && (
              <FixSubject style={{padding: '0px'}}>
                <input type='text' placeholder='과목명 작성' onChange={(e) => setNewSubjectName(e.target.value)} />
                <button className='fix' onClick={addSubject}>등록</button>
                <button className='delete' onClick={() => setIsAdd(false)}>취소</button>
              </FixSubject>
            )
          ) : (
            <SelectSubjectArea>
              <LectureHeader>
                <div className='title'>
                  <p>{selectSubject?.name}</p>
                </div>
              </LectureHeader>
              <span onClick={() => setIsFix(true)}>과목 수정</span>
              {isFix && 
                <FixSubject className='fixArea'>
                  <input type='text' placeholder={selectSubject.name} onChange={(e) => setSubjectName(e.target.value)} />
                  <button className='fix' onClick={fixSubject}>수정</button>
                  <button className='delete' onClick={() => setIsFix(false)}>취소</button>
                </FixSubject>
              }
              <span onClick={deleteSubject}>과목 삭제</span>
            </SelectSubjectArea>
          )}
        </SubjectEditWrapper>
      </SubjectDisplay>
    </AddModalDisplay>
  )
}

export default React.memo(Subject);