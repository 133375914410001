import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/lecture/' // (26)강의를 듣는 학생 목록 조회

let swrData: any = getPersistData(KEY)

export function useLectureStudents(lectureId) {
	const { data: token } = useToken()
	const QUERYKEY = lectureId ? KEY + lectureId + '/student?page=1&maxData=1000' : null

	const { data, mutate } = useSWR<any>(
		QUERYKEY,
		async url => {
			const response = await API.get(url, token + '', {})
			swrData = response.result?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		LectureStudentsData: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
