import React, { useState, useMemo } from 'react';
import { LectureGrade, LectureGradeHeader, LectureListDisplay, LectureListHeader, LectureListSearch, LectureListWrapper, LectureTotal } from '../Lecture/styles';
import { Icon } from '../Navigation/styles';
import { CiSearch } from "react-icons/ci";
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList';

const StudentList = ({ setIsAddStudent, setIsTotal, selectStudent, setSelectStudent, selectedGrade, setSelectedGrade, setSelectStudentNote }) => {
  const { TeacherStudentData: StudentAll, mutate: StudentAllMutate } = useTeacherStudent(3);
  const [searchKeyword, setSearchKeyword] = useState('');
  console.log(StudentAll);

  StudentAllMutate(StudentAll);

  const renderStudentGrades = (schoolType, grade) => {
    const filteredStudents = StudentAll?.filter(student => student.status === 1).filter(student => student.schoolType === schoolType && student.grade === grade).filter(student => student.name?.toLowerCase().includes(searchKeyword.toLowerCase()))

    return (
      <>
        <LectureGradeHeader onClick={() => {setSelectedGrade(schoolType + grade); setIsTotal(true); setSelectStudent(null)}}>
          <p>{schoolType + grade}</p>
          <span>{filteredStudents?.length}</span>
        </LectureGradeHeader>
        {filteredStudents?.map((student, idx) => (
          <LectureGrade
            key={idx}
            onClick={() => { setIsTotal(false); setSelectStudent(student.id); setSelectStudentNote(student) }}
            style={{
              backgroundColor: selectStudent === student.id ? '#F78B8580' : 'transparent',
              boxShadow: selectStudent === student.id ? '0px 0px 20px 0px #CE636326 inset' : '',
              display: selectedGrade === schoolType + grade || searchKeyword !== '' ? 'flex' : 'none'
            }}
          >
            <p>{student.name}</p>
            <span style={{ backgroundColor: '#E8D7FF80', color: '#441099' }}>{student.schoolType}{student.grade}</span>
          </LectureGrade>
        ))}
      </>
    );
  };

  // 퇴원생 목록
  const dischargeStudents = StudentAll?.filter(student => student.status === 0).filter(student => student.name?.toLowerCase().includes(searchKeyword.toLowerCase()))
  
  return (
    <LectureListWrapper>
      <LectureListHeader>
        <p>학생리스트</p>
        <button onClick={() => setIsAddStudent(true)}>학생등록</button>
      </LectureListHeader>
      <LectureListSearch>
        <input type='text' placeholder='학생 검색' value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
        <Icon style={{ color: '#DDAFAF', scale: '1.5' }}><CiSearch /></Icon>
      </LectureListSearch>
      <LectureTotal onClick={() => { setIsTotal(true); setSelectStudent(undefined); setSelectedGrade('전체'); }}>
        <p>전체</p>
        <span>{StudentAll?.length}</span>
      </LectureTotal>
      <LectureListDisplay>
        {renderStudentGrades('', '무학년')}
        {renderStudentGrades('', 'N수')}
        {renderStudentGrades('고', '3')}
        {renderStudentGrades('고', '2')}
        {renderStudentGrades('고', '1')}
        {renderStudentGrades('중', '3')}
        {renderStudentGrades('중', '2')}
        {renderStudentGrades('중', '1')}
        {renderStudentGrades('초', '6')}
        {renderStudentGrades('초', '5')}
        {renderStudentGrades('초', '4')}
        {renderStudentGrades('초', '3')}
        {renderStudentGrades('초', '2')}
        {renderStudentGrades('초', '1')}
        <LectureGradeHeader onClick={() => {setSelectedGrade('퇴원생'); setIsTotal(true);}}>
          <p>퇴원생</p>
          <span>{dischargeStudents?.length}</span>
        </LectureGradeHeader>
        {dischargeStudents?.map((student, idx) => (
          <LectureGrade
            key={idx}
            onClick={() => { setIsTotal(false); setSelectStudent(student.id); setSelectStudentNote(student) }}
            style={{
              backgroundColor: selectStudent === student.id ? '#F78B8580' : 'transparent',
              boxShadow: selectStudent === student.id ? '0px 0px 20px 0px #CE636326 inset' : '',
              display: selectedGrade === '퇴원생' || searchKeyword !== '' ? 'flex' : 'none'
            }}
          >
            <p>{student.name}</p>
            <span style={{ backgroundColor: '#E8D7FF80', color: '#441099' }}>{student.schoolType}{student.grade}</span>
          </LectureGrade>
        ))}
      </LectureListDisplay>
    </LectureListWrapper>
  );
};

export default React.memo(StudentList);