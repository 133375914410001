import styled from "styled-components";
import { Icon } from "../Navigation/styles";

export const LectureWrapper = styled.div`
  padding: 30px 300px;
  display: flex;
  gap: 14px;
  min-width: 360px;
  padding-bottom: 0px;
  @media (max-width: 1700px) {
    padding: 30px 220px;
  }
  @media (max-width: 1550px) {
    padding: 30px 130px;
  }
  @media (max-width: 1350px) {
    padding: 30px 80px;
  }
  @media (max-width: 1250px) {
    padding: 30px 50px;
  }
  @media (max-width: 1100px) {
    padding: 30px 20px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 700px) {
    padding: 20px 12px;
  }
`
export const LectureListWrapper = styled.div`
  width: 288px;
  max-height: 840px;
  border: 3px solid #F1EBEB;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`
export const LectureListHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    color: #222222;
  }
  button {
    font-family: 'Pretendard-Regular';
    border-radius: 4px;
    padding: 6px 10px;
    background-color: #222222;
    color: #fff;
    border: 1px solid #676767;
    transition: .3s;
    &:hover {
      background-color: #676767;
    }
  }
`
export const LectureListSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  background-color: #FFF9F9;
  border: 1px solid #DDAFAF;
  ${Icon} {
    scale: 1.5;
    transform: translateY(-1px);
  }
  input {
    background-color: transparent;
    width: calc(100% - 30px);
    border: none;
    &::placeholder {
      color: #BA7070;
      font-size: 14px;
      font-weight: 400;
    }
  }
`
export const LectureListDisplay = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .mobileTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    .tableRow {
      display: flex;
      align-items: center;
      width: 100%;
      .tableEl {
        width: 50%;
        display: flex;
        align-items: center;
      }
    }
    .th {
      border: 1px solid #F6F1F1;
      background-color: #FFF5F5;
      color: #666666;
      font-size: 13px;
      text-align: center;
      font-weight: 400;
      letter-spacing: -1px;
      padding: 15px 10px;
      width: 60px;
    }
    .td {
      padding: 0 12px;
      font-size: 15px;
      letter-spacing: -1px;
      color: #111111;
      font-weight: 400;
    }
  }
`
export const LectureTotal = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAE7E7;
  cursor: pointer;
  p {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -1px;
    color: #111111;
  }
  span {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #666666;
  }
`
export const LectureGradeHeader = styled.div`
  width: 100%;
  height: 28px;
  padding: 5.5px 16px;
  border-bottom: 1px solid #F3F3F3;
  background-color: #FFF5F5;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #111111;
  }
  span {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #DC3838;
  }
`
export const LectureGrade = styled.div`
  width: 100%;
  height: 41px;
  padding: 12px 25px;
  border-bottom: 1px solid #F6F1F1;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #111111;
  }
  span {
    padding: 0px 4px;
    border-radius: 2px;
    height: 18px;
    min-width: 29px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -1px;
  }
  &:hover {
    background-color: #FAE7E7 !important;
  }
`
export const LectureView = styled.div`
  width: calc(100% - 298px);
  .scroll {
    overflow-y: auto;
    height: 786px;
  }
  .teacherMobile {
    display: none;
  }
  @media (max-width: 1200px) {
    .scroll {
      .lectureTotal {
        .time {
          display: none;
        }
      }
      .studentTotal {
        .school, .isAcademy {
          display: none;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 690px) {
    .teacherMobile {
      display: block;
    }
    .teacherTbody {
      display: none;
    }
  }
`
export const LectureViewHeader = styled.div`
  width: 100%;
  height: 54px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F78B85;
  p {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -1px;
    color: #fff;
  }
  .checkArea {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  label {
    color: #222222;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -1px;
    cursor: pointer;
  }
  .checkboxShape {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    .icon {
      width: 18px;
      height: 18px;
      border: 1px solid #D2D2D2;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #D2D2D2;
      cursor: pointer;
      &.checked {
        color: #F78B85;
      }
    }
  }
  .lessonNoteBtn {
    border: 1px solid #525252;
    padding: 6px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: #fff;
    p {
      color: #222222;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -1px;
    }
    ${Icon} {
      color: #222222;
    }
  }
  .fixName {
    border: 1px solid #d9d9d9;
    padding: 8px 10px;
    border-radius: 4px;
  }
`
export const FixBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .delete {
    background-color: #F06B64;
    color: #fff;
    border-radius: 4px;
    padding: 5px 8px;
    border: 1px solid #F06B64;
    &:hover {
      border: 1px solid #FFF;
    }
  }
  .fix {
    background-color: #fff;
    color: #F06B64;
    border-radius: 4px;
    padding: 5px 8px;
    border: 1px solid #fff;
    &:hover {
      border: 1px solid #F06B64;
    }
  }
`
export const LectureViewTable = styled.table`
  width: 100%;
  text-align: center;
  overflow-y: auto;
  input {
    width: 100%;
    padding: 6px 8px;
    font-family: 'Pretendard-Regular';
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    &::placeholder {
      font-family: 'Pretendard-Regular';
    }
  }
  select {
    padding: 4px 5px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    &::placeholder {
      font-family: 'Pretendard-Regular';
    }
  }
  tr {
    border-left: 1px solid #F6F1F1;
  }
  th {
    padding: 12.5px;
    background-color: #FAE7E7;
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -1px;
  }
  td {
    padding: 12.5px;
    height: 40px;
    color: #111111;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -1px;
    border-bottom: 1px solid #F6F1F1;
    border-right: 1px solid #F6F1F1;
  }
  .lecture {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    button {
      display: flex;
      align-items: center;
      padding: 5px 8px;
      border-radius: 4px;
      gap: 2px;
    }
  }
  .person {
    color: #666666;
    span {
      color: #F06B64;
    }
  }
  .nameNoteArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .manageNoteBtn {
      display: flex;
      align-items: center;
      padding: 5px 8px;
      border-radius: 4px;
    }
  }
  .charge {
    text-align: left;
  }
  .scroll {
    width: 100%;
  }
  .date {
    display: flex;
    align-items: center;
    gap: 2px;
    p {
      background-color: #F3F3F3;
      padding: 2px 3px;
      border-radius: 2px;
      color: #666666;
      font-size: 16px;
      font-weight: 400;
      &:hover {
        background-color: #F78B85;
        color: #fff;
      }
      &.selected {
        background-color: #F78B85;
        color: #fff;
      }
    }
  }
  @media (max-width: 800px) {
    th {
      &:nth-child(1), 
      &:nth-child(3), 
      &:nth-child(7), 
      &:nth-child(8), 
      &:nth-child(9), 
      &:nth-child(10) {
        display: none;
      }
    }
    td {
      &:nth-child(1), 
      &:nth-child(3), 
      &:nth-child(7), 
      &:nth-child(8), 
      &:nth-child(9), 
      &:nth-child(10) {
        display: none;
      }
    }
  }
`
export const StudentViewTable = styled.table`
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  input {
    width: 100%;
    padding: 6px 8px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    &::placeholder {
      font-family: 'Pretendard-Regular';
    }
  }
  select {
    padding: 4px 5px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    &::placeholder {
      font-family: 'Pretendard-Regular';
    }
  }
  tr {
    border-left: 1px solid #F6F1F1;
  }
  th {
    padding: 12.5px;
    background-color: #FAE7E7;
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -1px;
    white-space: nowrap;
  }
  td {
    padding: 12.5px;
    height: 40px;
    color: #111111;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -1px;
    border-bottom: 1px solid #F6F1F1;
    border-right: 1px solid #F6F1F1;
    white-space: nowrap;
  }
  .mobile {
    display: none;
  }
  .lecture {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    button {
      display: flex;
      align-items: center;
      padding: 5px 8px;
      border-radius: 4px;
      gap: 2px;
    }
  }
  .studentTbody {
    input {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    .studentTbody {
      display: none;
    }
    .mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      > div {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F6F1F1;
        border-right: 1px solid #F6F1F1;
        > div {
          display: flex;
          align-items: center;
          width: 50%;
        }
      }
      .th {
        padding: 12.5px 0px;
        background-color: #FAE7E7;
        color: #666666;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -1px;
        width: 60px;
      }
      .td {
        padding: 12.5px;
        height: 40px;
        color: #111111;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -1px;
        border-bottom: 1px solid #F6F1F1;
        border-right: 1px solid #F6F1F1;
      }
    }
  }
  @media (max-width: 690px) {
    .teacherTbody {
      display: none;
    }
  }
`
export const AcademyViewTable = styled.table`
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  tr {
    border-left: 1px solid #F6F1F1;
  }
  th {
    padding: 12.5px;
    background-color: #FAE7E7;
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -1px;
  }
  td {
    padding: 12.5px;
    height: 40px;
    color: #111111;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -1px;
    border-bottom: 1px solid #F6F1F1;
    border-right: 1px solid #F6F1F1;
  }
  .trHover {
    &:hover {
      background-color: #FAE7E7;
    }
  }
  @media (max-width: 1120px) {
    th { 
      &:nth-child(7) {
        display: none;
      }
    }
    td { 
      &:nth-child(7) {
        display: none;
      }
    }
  }
  @media (max-width: 800px) {
    th {
      &:nth-child(1), 
      &:nth-child(6) {
        display: none;
      }
    }
    td {
      &:nth-child(1), 
      &:nth-child(6) {
        display: none;
      }
    }
  }
  @media (max-width: 590px) {
    .phone {
      display: none;
    }
  }
`