import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/users/' // (6) 강사/학생 선택시 강의 목록 조회

let swrData: any = getPersistData(KEY)

export function useGetUserClass(userId) {
	const { data: token } = useToken()

	const QUERYKEY = userId ? KEY + userId + 'class?page=1&maxData=1000' : null
	const { data, mutate } = useSWR<any>(
		QUERYKEY,
		async (url) => {
			const response = await API.get(url, token + '', {})
			swrData = response.result?.result

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		UserClassData: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
