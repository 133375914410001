import React, {useEffect} from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import * as PAGE from './pagesImport'
import { GlobalStyle } from 'styles/global-styles'
import { NotFoundPage } from './components/NotFoundPage/Loadable'
import { useToken } from './hooks'
import { LogoutPage } from './CMSPages/CMSLogoutPage'
import { GomsAcademy } from './gomsacademy/organism/Main/index'
import {Dailyreport} from "./gomsacademy/organism/Dailyreport";

export function Routes() {
	const { data: token } = useToken()

	useEffect(() => {
		console.log('token', token);
	}, [token]);

	return (
		<BrowserRouter>
			<GlobalStyle />
			<Switch>
				<Route exact path="/">
          {token ? <Redirect to="/admin" /> : <PAGE.LoginPage />}
        </Route>
				<Route path="/admin">
					<Route exact path="/admin/" component={token ? GomsAcademy : PAGE.LoginPage} />
					<Route
						exact
						path="/admin/login/"
						component={token ? GomsAcademy : PAGE.LoginPage}
					/>
					<Route exact path="/admin/logout/" component={LogoutPage} />

					{/* ** 학원생 관리 시스템 ===== */}
					<Route  path="/admin/academy/" component={token ? GomsAcademy : PAGE.LoginPage} />
				</Route>
				<Route path='/dailyreport' component={Dailyreport} />
				<Route path="*" component={NotFoundPage} />
			</Switch>
		</BrowserRouter>
	)
}
