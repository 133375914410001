import React from 'react'

export const Payment = () => {
  return (
    <div style={{width: '100%', height: '50vh', position: 'relative'}}>
      <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <h1>해당 기능은 준비 중입니다.🙏</h1>
      </div>
    </div>
  )
}

export default React.memo(Payment)