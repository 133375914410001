import React, {useEffect, useState} from 'react'
import {
	AddModalBtns,
	AddModalDisplay,
	AddModalHeader,
	AddModalMain,
	AddModalTable,
	Area,
	Section,
	TableMain,
	TableTitle,
} from './styles/addModalStyles'
import { Blacked, Icon } from '../organism/Navigation/styles'
import { FaChevronLeft } from 'react-icons/fa'
import API from 'utils/api'
import { useToken } from 'app/hooks'
import { useLectureList } from '../hooks/LectureList'
import { Input, message } from 'antd'
import { useTeacherStudent } from '../hooks/TeacherStudentList'
import {TeacherStudentSide} from "../hooks/TeacherStudentSide";

export const AddLectureModal = ({ setIsAddLecture }) => {
	const { data: token } = useToken()
	const { mutate: LectureListMutate } = useLectureList(0)
	const { TeacherStudentData: TeacherList } = TeacherStudentSide(2)
	const { TeacherStudentData: DirectorList } = TeacherStudentSide(1)

	const today = new Date().toISOString().split('T')[0]

	const [className, setClassName] = useState('')
	const [teacher, setTeacher] = useState()
	const [type, setType] = useState<number>(1)
	const [target, setTarget] = useState('무학년')
	const [total, setTotal] = useState('')
	const [time, setTime] = useState('')
	const [startDate, setStartDate] = useState(today)
	const [endDate, setEndDate] = useState('')
	const [grade, setGrade] = useState(null)

	const [selectedDays, setSelectedDays] = useState<string[]>([])

	useEffect(() => {
		console.log('TeacherList', TeacherList);
		console.log('DirectorList', DirectorList);
	}, [DirectorList]);

	const toggleDay = (day: string) => {
		setSelectedDays(prevSelectedDays => {
			if (prevSelectedDays.includes(day)) {
				return prevSelectedDays.filter(it => it !== day)
			} else {
				return [...prevSelectedDays, day]
			}
		})
	}

	const convertDaysToBinary = () => {
		const binaryArray = ['0', '0', '0', '0', '0', '0', '0']
		selectedDays?.forEach(day => {
			switch (day) {
				case '월':
					binaryArray[0] = '1'
					break
				case '화':
					binaryArray[1] = '1'
					break
				case '수':
					binaryArray[2] = '1'
					break
				case '목':
					binaryArray[3] = '1'
					break
				case '금':
					binaryArray[4] = '1'
					break
				case '토':
					binaryArray[5] = '1'
					break
				case '일':
					binaryArray[6] = '1'
					break
				default:
					break
			}
		})
		return binaryArray?.join('')
	}

	const saveClassName = e => {
		setClassName(e.target.value)
	}
	const saveTeacher = e => {
		setTeacher(e.target.value)
	}
	const saveType = e => {
		setType(parseInt(e.target.value, 10))
	}
	const saveTarget = e => {
		setTarget(e.target.value)
		setGrade(e.target.options[e.target.selectedIndex].getAttribute('data-id'))
		if (e.target.value == '무학년') {
			setGrade(null)
		}
	}
	const saveTotal = e => {
		const numericValue = e.target.value.replace(/[^0-9]/g, '')

		// 추가된 부분: 숫자가 아닌 경우 메시지 표시
		if (!/^\d+$/.test(numericValue)) {
			message.error('정원은 숫자만 입력해주세요.')
		} else {
			setTotal(numericValue)
		}
	}
	const saveTime = e => {
		setTime(e.target.value)
	}
	const saveStartDate = e => {
		setStartDate(e.target.value)
	}
	const saveEndDate = e => {
		setEndDate(e.target.value)
	}
	const handleSave = () => {
		if (className == '') {
			message.error('강의명을 입력해주세요.')
		}
		if (teacher == null) {
			message.error('강사님을 배정해주세요.')
		}
		if (selectedDays?.length == 0) {
			message.error('수업요일을 선택해주세요.')
		}
		if (className !== '' && teacher !== null && selectedDays?.length !== 0) {
			API.post('/lecture', token + '', {
				name: className,
				teacherIds: [teacher],
				type: type,
				grade: grade,
				schoolType: target,
				maxCount: total,
				days: convertDaysToBinary(),
				time: time,
				startedAt: startDate,
				endedAt: endDate,
			})
				.then((res: any) => {
					if (res.result.success) {
						message.success('등록되었습니다')
						LectureListMutate()
						setIsAddLecture(false)
					}
				})
				.catch((err: any) => {})
		}
	}

	return (
		<AddModalMain>
			<Blacked onClick={() => setIsAddLecture(false)} />
			<AddModalDisplay>
				<AddModalHeader>
					<Icon onClick={() => setIsAddLecture(false)}>
						<FaChevronLeft />
					</Icon>
					<p>강의등록</p>
					<AddModalBtns>
						<button className="save" onClick={handleSave}>
							저장
						</button>
					</AddModalBtns>
				</AddModalHeader>
				<AddModalTable>
					<Area className="first">
						<TableTitle>강의명 *</TableTitle>
						<TableMain>
							<input type="text" placeholder="강의명작성" onChange={saveClassName} />
						</TableMain>
					</Area>
					<Area className="second">
						<Section>
							<TableTitle>강사 *</TableTitle>
							<TableMain>
								<select onChange={saveTeacher}>
									<option>강사 배정</option>
									{DirectorList?.map((it, idx) => (
										<option key={idx} value={it.id}>
											{it.name}
										</option>
									))}
									{TeacherList?.map((it, idx) => (
										<option key={idx} value={it.id}>
											{it.name}
										</option>
									))}
								</select>
							</TableMain>
						</Section>
						<Section>
							<TableTitle>강의방식 *</TableTitle>
							<TableMain>
								<select onChange={saveType}>
									<option value="1">관리식</option>
									<option value="2">강의식</option>
								</select>
							</TableMain>
						</Section>
					</Area>
					<Area className="fourth">
						<Section>
							<TableTitle>수업요일 *</TableTitle>
							<TableMain className="date">
								<p
									onClick={() => toggleDay('월')}
									className={selectedDays.includes('월') ? 'selected' : ''}>
									월
								</p>
								<p
									onClick={() => toggleDay('화')}
									className={selectedDays.includes('화') ? 'selected' : ''}>
									화
								</p>
								<p
									onClick={() => toggleDay('수')}
									className={selectedDays.includes('수') ? 'selected' : ''}>
									수
								</p>
								<p
									onClick={() => toggleDay('목')}
									className={selectedDays.includes('목') ? 'selected' : ''}>
									목
								</p>
								<p
									onClick={() => toggleDay('금')}
									className={selectedDays.includes('금') ? 'selected' : ''}>
									금
								</p>
								<p
									onClick={() => toggleDay('토')}
									className={selectedDays.includes('토') ? 'selected' : ''}>
									토
								</p>
								<p
									onClick={() => toggleDay('일')}
									className={selectedDays.includes('일') ? 'selected' : ''}>
									일
								</p>
							</TableMain>
						</Section>
						<Section>
							<TableTitle>학습시간 </TableTitle>
							<TableMain>
								<Input type={'text'} placeholder="시간입력 예) 2:30" onChange={saveTime} />
							</TableMain>
						</Section>
					</Area>
					<Area className="third">
						<Section>
							<TableTitle>대상</TableTitle>
							<TableMain>
								<select onChange={saveTarget}>
									<option value="무학년">무학년</option>
									<option value="" data-id="0">
										N수생
									</option>
									<option value="고" data-id="3">
										고등/고3
									</option>
									<option value="고" data-id="2">
										고등/고2
									</option>
									<option value="고" data-id="1">
										고등/고1
									</option>
									<option value="중" data-id="3">
										중등/중3
									</option>
									<option value="중" data-id="2">
										중등/중2
									</option>
									<option value="중" data-id="1">
										중등/중1
									</option>
									<option value="초" data-id="6">
										초등/초6
									</option>
									<option value="초" data-id="5">
										초등/초5
									</option>
									<option value="초" data-id="4">
										초등/초4
									</option>
									<option value="초" data-id="3">
										초등/초3
									</option>
									<option value="초" data-id="2">
										초등/초2
									</option>
									<option value="초" data-id="1">
										초등/초1
									</option>
								</select>
							</TableMain>
						</Section>
						<Section>
							<TableTitle>정원</TableTitle>
							<TableMain>
								<Input type="number" placeholder="정원입력 예) 20" onChange={saveTotal} />
							</TableMain>
						</Section>
					</Area>
					<Area className="fifth">
						<Section>
							<TableTitle>개강일</TableTitle>
							<TableMain>
								<input type="date" value={startDate} onChange={saveStartDate} />
							</TableMain>
						</Section>
						<Section>
							<TableTitle>종강일</TableTitle>
							<TableMain>
								<input type="date" placeholder="날짜지정" onChange={saveEndDate} />
							</TableMain>
						</Section>
					</Area>
				</AddModalTable>
			</AddModalDisplay>
		</AddModalMain>
	)
}

export default React.memo(AddLectureModal)
