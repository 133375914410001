import React, { useState } from 'react'
import { AddModalBtns, AddModalDisplay, AddModalHeader, AddModalMain, AddModalTable, Area, Section, TableMain, TableTitle } from './styles/addModalStyles'
import { Blacked, Icon } from '../organism/Navigation/styles'
import { FaChevronLeft } from "react-icons/fa";
import { useToken } from 'app/hooks';
import API from 'utils/api';
import { message } from 'antd';
import { useGetSubject } from '../hooks/GetSubjectList';
import { useTeacherStudent } from '../hooks/TeacherStudentList';
import Subject from './Subject';

export const AddTeacherModal = ({ setIsAddTeacher }) => {
  const { data:token } = useToken();
  const { SubjectList } = useGetSubject();
  const { mutate:TeacherListMutate } = useTeacherStudent(2)
  const { mutate:ManagerListMutate } = useTeacherStudent(4)
  const today = new Date().toISOString().split('T')[0];
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subject, setSubject] = useState<number>(0);
  const [number, setNumber] = useState('');
  const [startDate, setStartDate] = useState(today);
  const [isSubject, setIsSubject] = useState(false);
  const [Auth, setAuth] = useState('2')

  const saveName = (e) => {
    setName(e.target.value);
  }
  const saveSubject = (e) => {
    setSubject(parseInt(e.target.value, 10));
  }
  const saveNumber = (e) => {
    setNumber(e.target.value);
  }
  const saveStartDate = (e) => {
    setStartDate(e.target.value);
  }

  const handleSave = () => {
    if(name == '') {
      message.error('이름을 입력해주세요.')
    } else if (email == '') {
      message.error('아이디를 입력해주세요')
    } else if (password == '') {
      message.error('비밀번호를 입력해주세요')
    }
    API.post('/users', token + '', {
      userType: Auth,
      name: name,
      phone: number,
      email: email,
      password: password,
      subjectIds: [
        subject
      ],
      school: "-",
      schoolType: "-",
      grade: 0,
      phone2: "-"
    })
    .then((res: any) => {
      if (res.result.success) {
        message.success('등록되었습니다');
        TeacherListMutate();
        ManagerListMutate();
        setIsAddTeacher(false);
      }
    })
    .catch((err: any) => {})
  }

  return (
    <AddModalMain>
      <Blacked onClick={() => setIsAddTeacher(false)} />
      {isSubject 
      ? <Subject setIsSubject={setIsSubject} /> 
      : (
        <AddModalDisplay>
        <AddModalHeader>
          <Icon onClick={() => setIsAddTeacher(false)}><FaChevronLeft /></Icon>
          <p>직원등록</p>
          <AddModalBtns>
            <p 
            style={{fontSize: '16px', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'normal', marginRight: '10px'}} 
            onClick={() => setIsSubject(true)}
            >
              과목관리
            </p>
            <button className='save' onClick={handleSave}>저장</button>
          </AddModalBtns>
        </AddModalHeader>
        <AddModalTable>
          <Area className='second'>
            <Section>
              <TableTitle>직원명 *</TableTitle>
              <TableMain>
                <input type='text' placeholder='이름 작성' onChange={saveName} />
              </TableMain>
            </Section>
            <Section>
              <TableTitle>과목 *</TableTitle>
              <TableMain>
                <select onChange={saveSubject}>
                  <option value='0'>과목선택</option>
                  {SubjectList?.map((it) => (
                    <option value={it.id}>{it.name}</option>
                  ))}
                </select>
              </TableMain>
            </Section>
          </Area>
          <Area className='second'>
            <Section>
              <TableTitle>아이디 *</TableTitle>
              <TableMain>
                <input type='text' placeholder='아이디 작성' onChange={(e) => setEmail(e.target.value)} />
              </TableMain>
            </Section>
            <Section>
              <TableTitle>비밀번호 *</TableTitle>
              <TableMain>
                <input type='text' placeholder='비밀번호 작성' onChange={(e) => setPassword(e.target.value)} />
              </TableMain>
            </Section>
          </Area>
          <Area className='third'>
            <Section>
              <TableTitle>전화번호</TableTitle>
              <TableMain>
                <input type='text' placeholder='전화번호 작성' onChange={saveNumber} />
              </TableMain>
            </Section>
            <Section>
              <TableTitle>권한</TableTitle>
              <TableMain>
                <select onChange={(e) => setAuth(e.target.value)}>
                  <option value={2}>강사</option>
                  <option value={4}>학원실장</option>
                </select>
              </TableMain>
            </Section>
          </Area>
          <Area className='fourth'>
            <Section>
              <TableTitle>등록일</TableTitle>
              <TableMain>
                <input type='date' value={startDate} onChange={saveStartDate} />
              </TableMain>
            </Section>
          </Area>
        </AddModalTable>
      </AddModalDisplay>
      )}
    </AddModalMain>
  )
}

export default React.memo(AddTeacherModal);