import { Navigation } from 'app/gomsacademy/organism/Navigation/index'
import React, { useState } from 'react'
import { Home } from '../Home'
import MobileSide from '../NavigationContent/MobileSide'
import Lecture from '../Lecture'
import LectureStyleModal from 'app/gomsacademy/components/LectureStyleModal'
import ManageStyleModal from 'app/gomsacademy/components/ManageStyleModal'
import Student from '../Student'
import Teacher from '../Teacher'
import { Main } from './styles'
import Academy from '../Academy'
import { useTeacherStudent } from 'app/gomsacademy/hooks/TeacherStudentList'
import EditInfo from 'app/gomsacademy/components/EditInfo'
import { useLocation } from 'react-router-dom'
import Attendance from '../Attendance'
import Note from '../Note'
import Payment from '../Payment'

interface selectLectureProps {
	grade: string
	id: number
	maxCount: number
	name: string
	teacherNames: [string]
	time: string
	totalCount: number
	type: string
}

export const GomsAcademy = () => {
	const location = useLocation()
	const { pathname } = location
	const current = pathname.split('/admin/academy/')[1]

	const [menu, setMenu] = useState<string>(current || '')
	const [openSide, setOpenSide] = useState(false)
	const { TeacherStudentData: StudentAll } = useTeacherStudent(3)

	// ** 정보수정?
	const [isEdit, setIsEdit] = useState(false)
	// ** 강의선택
	const [selectLecture, setSelectLecture] = useState<selectLectureProps | undefined>()
	// ** 각 학생 학생일지 (모달 오픈, 모달에 props전달)
	const [selectStudentNote, setSelectStudentNote] = useState<selectLectureProps | undefined>()
	// ** 각 학생 학생일지 클릭 (idx로 구분)
	const [isWrite, setIsWrite] = useState<{ id: number; student: number; value: boolean }[]>([])
	// ** 각 학생 비고란 수정 (idx로 구분)
	const [editNoteIndex, setEditNoteIndex] = useState<{ id: number | null; student: number | null }>(
		{ id: null, student: null }
	)
	// ** 강의식 학생일지 모달
	const [lectureSt, setLectureSt] = useState(false)
	// ** 관리식 학생일지 모달
	const [manageSt, setManageSt] = useState(false)
	console.log('isWrite', isWrite)

	const handleWriteClick = index => {
		setIsWrite(prevStates => {
			const newStates = prevStates?.map(item => ({
				id: item.id,
				student: item.student,
				value: item.value,
			}))

			if (!newStates.some(item => item.id === index.id && item.student === index.student)) {
				newStates.push({ id: index.id, student: index.student, value: true })
			}

			return newStates
		})
	}

	return (
		<Main className={'Main'}>
			<Navigation setOpenSide={setOpenSide} setMenu={setMenu} menu={menu} setIsEdit={setIsEdit} />
			<MobileSide menu={menu} setMenu={setMenu} openSide={openSide} setOpenSide={setOpenSide} />
			{menu == '' && (
				<Home
					setSelectStudentNote={setSelectStudentNote}
					setManageSt={setManageSt}
					isWrite={isWrite}
					setIsWrite={setIsWrite}
					setLectureSt={setLectureSt}
					editNoteIndex={editNoteIndex}
					setEditNoteIndex={setEditNoteIndex}
					selectLecture={selectLecture}
					setSelectLecture={setSelectLecture}
				/>
			)}
			{menu == 'lecture' && (
				<Lecture
					isWrite={isWrite}
					setIsWrite={setIsWrite}
					setManageSt={setManageSt}
					setSelectStudentNote={setSelectStudentNote}
					setLectureSt={setLectureSt}
					selectLecture={selectLecture}
					setSelectLecture={setSelectLecture}
					setEditNoteIndex={setEditNoteIndex}
				/>
			)}
			{menu == 'student' && (
				<Student
					setSelectLecture={setSelectLecture}
					setSelectStudentNote={setSelectStudentNote}
					isWrite={isWrite}
					setManageSt={setManageSt}
					setEditNoteIndex={setEditNoteIndex}
					selectLecture={selectLecture}

				/>
			)}
			{menu == 'employee' && (
				<Teacher
					isWrite={isWrite}
					setIsWrite={setIsWrite}
					setLectureSt={setLectureSt}
					setSelectLecture={setSelectLecture}
				/>
			)}
			{menu == 'academy' && <Academy />}
			{menu == 'attendance' && (
				<Attendance selectLecture={selectLecture} setSelectLecture={setSelectLecture} />
			)}
			{menu == 'payment' && <Payment />}
			{menu == 'note' && <Note />}
			{isEdit && <EditInfo setIsEdit={setIsEdit} />}
			{lectureSt && <LectureStyleModal setLectureSt={setLectureSt} selectLecture={selectLecture} />}
			{manageSt && (
				<ManageStyleModal
					selectLecture={selectLecture}
					selectStudentNote={selectStudentNote}
					setManageSt={setManageSt}
					editNoteIndex={editNoteIndex}
					handleWriteClick={handleWriteClick}
					setIsWrite={setIsWrite}
				/>
			)}
		</Main>
	)
}

export default React.memo(GomsAcademy)
