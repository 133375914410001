import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/system/subject?page=1&maxData=1000' // (27) 과목 목록 조회

let swrData: any = getPersistData(KEY)

export function useGetSubject() {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(KEY, token + '', {})
			swrData = response.result?.result?.list

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		SubjectList: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
