import React, { useEffect, useState } from 'react'
import { AcademySelect, Btn, NavBtn, NavLeftArea, TitleWrapper } from '../Navigation/styles'
import { FaBars } from 'react-icons/fa6'
import { useAcademyList } from 'app/gomsacademy/hooks/AcademyList'
import API from 'utils/api'
import { useToken } from 'app/hooks'
import { message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useGetMyInfo } from 'app/gomsacademy/hooks/GetMyInfo'

const NavLeft = ({ setOpenSide, menu, setMenu }) => {
	const navigate = useHistory()
	const { data: token } = useToken()
	const { AcademyListData } = useAcademyList()
	const { myInfo, mutate: myInfoMutate } = useGetMyInfo()
	const location = useLocation()
	const { pathname } = location
	const current = pathname.split('/admin/academy/')[1]

	const handleTitleClick = () => {
		window.location.reload()
	}

	const handleBtnClick = menu => {
		navigate.push('/admin/academy/' + menu)
		setMenu(menu)
	}

	const [selectValue, setSelectValue] = useState(myInfo?.branchName || '')
	const [selectedOptionWidth, setSelectedOptionWidth] = useState(0)

	const handleChangeAcademy = e => {
		const branchId = e.target.options[e.target.selectedIndex].getAttribute('data-id')
		setSelectValue(e.target.value)
		API.post('/branch/' + branchId, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					message.success(e.target.value + '선택')
					myInfoMutate()
				}
			})
			.catch((err: any) => {})
	}

	useEffect(() => {
		if (selectValue?.length <= 5) {
			setSelectedOptionWidth(80)
		} else if (selectValue?.length >= 6) {
			setSelectedOptionWidth(110)
		}
	}, [selectValue])

	return (
		<NavLeftArea>
			<TitleWrapper>
				<div className="icon" onClick={() => setOpenSide(true)}>
					<FaBars />
				</div>
				{current !== 'academy' ? (
					<div className="title" onClick={handleTitleClick}>
						<h1 className="none">학원생 관리 시스템</h1>
						<p>
						GoMStem <br />
							{myInfo?.branchName}
						</p>
					</div>
				) : (
					<>
						<h1 onClick={handleTitleClick}>학원생 관리 시스템</h1>
						<AcademySelect
							style={{ width: selectedOptionWidth }}
							value={selectValue}
							onChange={handleChangeAcademy}>
							<option value="">학원선택</option>
							{AcademyListData?.map(it => (
								<option key={it.id} value={it.name} data-id={it.id}>
									{it.name}
								</option>
							))}
						</AcademySelect>
					</>
				)}
			</TitleWrapper>
			{current !== 'academy' && current !== 'note' ? (
				<NavBtn>
					<Btn onClick={() => handleBtnClick('')} className={menu === '' ? 'selected' : ''}>
						홈
					</Btn>
					{myInfo?.admin ? (
						<>
							<Btn
								onClick={() => handleBtnClick('lecture')}
								className={menu === 'lecture' ? 'selected' : ''}>
								강의관리
							</Btn>
							<Btn
								onClick={() => handleBtnClick('student')}
								className={menu === 'student' ? 'selected' : ''}>
								학생관리
							</Btn>
							<Btn
								onClick={() => handleBtnClick('employee')}
								className={menu === 'employee' ? 'selected' : ''}>
								직원관리
							</Btn>
						</>
					) :
					<>
						<Btn
							onClick={() => handleBtnClick('student')}
							className={menu === 'student' ? 'selected' : ''}>
							학생관리
						</Btn>
					</>
					}
					<Btn
						onClick={() => handleBtnClick('attendance')}
						className={menu === 'attendance' ? 'selected' : ''}>
						출결확인
					</Btn>
					{myInfo?.admin ? (
						<>
							<Btn
								onClick={() => handleBtnClick('payment')}
								className={menu === 'payment' ? 'selected' : ''}>
								결제관리
							</Btn>
						</>
					) : null}
				</NavBtn>
			) : (
				<NavBtn>
					<Btn
						onClick={() => handleBtnClick('academy')}
						className={menu === 'note' ? '' : 'selected'}>
						학원관리
					</Btn>
					<Btn onClick={() => handleBtnClick('note')} className={menu === 'note' ? 'selected' : ''}>
						오답노트
					</Btn>
				</NavBtn>
			)}
		</NavLeftArea>
	)
}

export default React.memo(NavLeft)
