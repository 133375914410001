import styled from "styled-components";
import { Blacked, Icon } from "../../organism/Navigation/styles";

export const ManageStyleModalMain = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  min-width: 360px;
  
`
export const ManageNote = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 800px;
  background-color: #fff;
  border-radius: 4px;
  padding: 36px 24px;
  z-index: 9;
  .modalMain {
    height: calc(100% - 73px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .teacherArea {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #111111;
      font-size: 16px;
      font-weight: 500;
      .vertical {
        margin: 0 10px;
        color: #CDCDCD;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .tableWrapper {
      .mobile {
        display: none;
      }
    }
  }
  @media (max-width: 1023px) {
    z-index: 999;
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0px;
    transform: translate(0px);
    border-radius: 0px;
    ${Blacked} {
      display: none;
    }
    .modalMain {
      .tableWrapper {
        .mobile {
          display: flex;
        }
        .notMobile {
          display: none;
        }
      }
      .teacherArea {
        display: none;
      }
    }
  }
`

export const SubManageNote = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 800px;
  background-color: #fff;
  border-radius: 4px;
  padding: 36px 24px;
  z-index: 9;
  .modalMain {
    height: calc(100% - 73px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .teacherArea {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #111111;
      font-size: 16px;
      font-weight: 500;
      .vertical {
        margin: 0 10px;
        color: #CDCDCD;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .tableWrapper {
      .mobile {
        display: none;
      }
    }
  }
  @media (max-width: 1023px) {
    z-index: 999;
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0px;
    transform: translate(0px);
    border-radius: 0px;
    ${Blacked} {
      display: none;
    }
    .modalMain {
      .tableWrapper {
        .mobile {
          display: flex;
        }
        .notMobile {
          display: none;
        }
      }
      .teacherArea {
        display: none;
      }
    }
  }
`

export const NoteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px; 
  position: relative;
  ${Icon} {
    display: none;
  }
  .message {
    width: 100px;
    height: 39px;
    border-radius: 4px;
    padding: 8px 10px;
    border: 1px solid #FD9C97;
    color: #FD9C97;
    background-color: #fff;
    &:hover {
      background-color: #FD9C97;
      color: #fff;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #111111;
    font-size: 22px;
    letter-spacing: -1px;
    font-weight: 600;
    cursor: pointer;
    span {
      text-decoration: underline;
    }
    p {
      &:first-child {
        margin-right: 4px;
      }
      &:last-child {
        color: #888888;
        font-size: 18px;
      }
    }
    .selectMonth {
      position: absolute;
      display: flex;
      align-items: center;
      top: 43px;
      gap: 6px;
      p {
        background-color: #F3F3F3;
        padding: 4px 7px;
        border-radius: 2px;
        color: #111111;
        font-size: 16px;
        font-weight: 400;
        &:hover {
          background-color: #F78B85;
          color: #fff;
        }
      }
    }
  }
  .rightBtns {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      text-decoration: underline;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
    }
    button {
      width: 100px;
      height: 39px;
      border: 1px solid #FD9C97;
      background-color: #F06B64;
      box-shadow: 0px 0px 20px 0px #78161626 inset;
      padding: 8px 10px;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -1px;
      color: #fff;
    }
    .mobileMessage {
      width: 60px;
      height: 39px;
      border-radius: 4px;
      padding: 8px 10px;
      border: 1px solid #FD9C97;
      color: #FD9C97;
      background-color: #fff;
      display: none;
      &:hover {
        background-color: #FD9C97;
        color: #fff;
      }
    }
  }
  @media (max-width: 1023px) {
    justify-content: flex-start;
    padding: 36px 12px;
    ${Icon} {
      display: block;
    }
    .message {
      display: none;
    }
    .rightBtns {
      position: absolute;
      right: 12px;
      gap: 6px;
      span {
        display: none;
      }
      button {
        width: 60px;
      }
      .mobileMessage {
        display: block;
      }
    }
    .selectMonth {
      left: 20px;
      top: 80px !important;
    }
  }
  @media (max-width: 544px) {
    padding-bottom: 50px;
    .rightBtns {
      gap: 3px;
      button {
        padding: 2px 3px !important;
      }
    }
    .selectMonth {
      width: 300px;
      flex-wrap: wrap;
    }
  }
  @media print {
    padding-bottom: 0px;
  }
`
export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #FFF5F5;
  p {
    &:first-child {
      color: #333333;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -1px;
    }
    &:last-child {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -1px;
    }
  }
  @media (max-width: 1023px) {
    margin-bottom: 26px;
  }
`
export const NoteTableWrapper = styled.div`
  height: 240px;
  overflow-y: auto;
  @media (max-width: 1023px) {
    height: 560px;
  }
`;
export const NoteTable = styled.table`
  width: 100%;
  text-align: center;
  border: 1px solid #F6F1F1;
  th {
    padding: 10px 20px;
    border: 1px solid #F6F1F1;
    border-top: none;
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -1px;
  }
  td {
    border: 1px solid #F6F1F1;
    padding: 10px 4px;
    color: #111111;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -1px;
    white-space: pre-wrap;
  }
  .date {
    width: 100px;
  }
  .content {
    text-align: left;
    line-height: normal;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      textarea {
        width: 100%;
        height: 100%;
      }
    }
  }
  .learningNote {
    position: relative;
    cursor: pointer;
    .btns {
      display: none;
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      button {
        padding: 3px 12px;
        border: none;
        border-radius: 4px;
        margin: 0 5px;
      }
      .fixBtn {
        background-color: #fff;
        color: #F06B64;
        transition: .3s;
        &:hover {
          background-color: #00000066;
          color: #FFF;
        }
      }
      .deleteBtn {
        background-color: #F06B64;
        color: #fff;
        transition: .3s;
        &:hover {
          background-color: #00000066;
          color: #FFF;
        }
      }
    }

    .btns2 {
      display: flex;
      justify-content: center;
      width: 130px;
      button {
        padding: 3px 12px;
        border: none;
        border-radius: 4px;
        margin: 0 5px;
      }
      .fixBtn {
        background-color: #fff;
        color: #F06B64;
        transition: .3s;
        border: 1px solid #F06B64;
        &:hover {
          background-color: #00000066;
          color: #FFF;
        }
      }
      .deleteBtn {
        background-color: #F06B64;
        color: #fff;
        transition: .3s;
        border: 1px solid transparent;
        &:hover {
          background-color: #00000066;
          color: #FFF;
        }
      }
    }
    
    &:hover {
      background-color: #F78B8580;
      .btns {
        display: block;
      }
    }
  }
  textarea {
    border: 1px solid #e4e1e1;
    padding: 10px;
    font-weight: 400;
    letter-spacing: -1px;
    resize: none;
    height: 100px;
  }
  input[type=text] {
    border: 1px solid #e4e1e1;
  }
  @media (max-width: 1023px) {
    display: none;
  }
`
export const AddTable = styled.div`
  position: absolute;
  background-color: #fff;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 9;
  padding: 20px;
  ${NoteTable} {
    border: none;
    td {
      border: none;
    }
    th {
      border: none;
    }
    input {
      width: 100%;
      padding: 8px 1px;
      text-align: center;
      border: 1px solid #e4e1e1;
      &:hover {
        background-color: #FFF5F5;
      }
      &:focus::placeholder {
        opacity: 0;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 4px;
      input {
        text-align: left;
        padding: 8px 4px;
      }
    }
    button {
      width: 100%;
      padding: 8px;
      border: 1px solid #e4e1e1;
      background-color: #FFF5F5;
      color: #666666;
      &:hover {
        border: 1px solid #000;
      }
    }
  }
  @media (max-width: 1023px) {
    display: none;
  }
`
export const IsAddBtn = styled.div`
  width: 100%;
  height: 47px;
  border: 1px solid #F6F1F1;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #FFF5F5;
  }
  @media (max-width: 1023px) {
    width: 95%;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    padding: 10px 0;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`

export const TextArea = styled.textarea`
  width: 100%;
  height: 130px;
  padding: 12px 16px;
  border-width: 0px, 1px, 1px, 1px;  
  border-style: solid;
  border-color: #F6F1F1;
  resize: none;
  &::placeholder {
    color: #AFAFAF;
    font-size: 16px;
  }
  @media (max-width: 1032px) {
    margin-top: -25px;
    height: 800px;
  }
`
export const MobileTable = styled.div`
  display: none;
  padding: 0 12px;
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .tableHeader {
      background-color: #FAE7E7;
      padding: 0 12px;
      border-radius: 4px 4px 0 0;
      display: flex;
      height: 36px;
      justify-content: space-between;
      align-items: center;
      p {
        &:first-child {
          font-size: 15px;
          font-weight: 500;
          color: #111111;
        }
        &:last-child {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }
      }
    }
    .tableMain {
      display: flex;
      flex-direction: column;
      gap: 3px;
      border: 1px solid #F6F1F1;
      border-top: none;
      background-color: #FAFAFA;
      .content, .assignment {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
      }
      .mobileContent {
        p {
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          textarea {
            width: 100%;
            min-height: 100px;
            resize: none;
            border: none;
            padding: 10px;
            background-color: #FAFAFA;
            &::placeholder {
              color: #AFAFAF;
              font-size: 15px;
              letter-spacing: -1px;
            }
          }
        }
      }
      .assignment {
        border-top: 1px solid #F6F1F1;
        border-radius: 0 0 4px 4px;
      }
      h1 {
        color: #666666;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -1px;
        width: 48px;
        padding: 10px 12px;
      }
      p {
        padding: 10px 12px;
        height: 100%;
        width: 100%;
        input {
          height: 100%;
          width: 100%;
          border: none;
          background-color: transparent;
          padding: 0px 10px;
          &::placeholder {
            color: #AFAFAF;
            font-size: 15px;
            letter-spacing: -1px;
          }
        }
      }
      @media print {
        border-top: 1px solid #F6F1F1;
        .assignment {
          border-top: 1px solid #F6F1F1;
        }
      }
    }
    .addbtn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      margin-top: 5px;
      button {
        border: 1px solid #F6F1F1;
        color: #111111;
        padding: 4px 20px;
        border-radius: 4px;
      }
    }
  }
`