import useSWR from 'swr'
import API from '../../../utils/api'
import { getPersistData } from '../../hooks/persist'
import { useToken } from 'app/hooks'

const KEY = '/users/excel' // (2-1) 엑셀 양식 다운로드

let swrData: any = getPersistData(KEY)

export function useGetExcel() {
	const { data: token } = useToken()
	const { data, mutate } = useSWR<any>(
		KEY,
		async () => {
			const response = await API.get(KEY, token + '', {})
			swrData = response.result?.link

			return swrData
		},
		{ revalidateOnFocus: true }
	)

	return {
		ExcelDown: data,
		mutate: (value?: any) => {
			if (value != undefined) {
				swrData = value
			}
			return mutate()
		},
	}
}
